export enum LkuWorkTypeGroupEnum {
  RESEARCH = "research",
  WORK = "work",
  INSTRUCTION = "instruction",
  REPAIR = "repair",
  OTHER = "other",
}

export enum CodeGroupEnum {
  LOCATION = "location",
  TREE = "tree",
}

export enum LkuEquipmentTypeCodeEnum {
  BKT = "bkt",
  KRT = "krt",
}

export enum LkuEquipmentTypeEnum {
  BKT = "БКТ",
  KRT = "КРТ",
}
