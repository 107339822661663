import { ReactElement } from "react";
import { Button, Row } from "antd";

import { isGroup, IGroup } from "core/shared/dashboard";
import { IDashboardShiftStatisticTasksByTeamsLkuTask } from "core/app/api/LKU/interfaces";
import { useTaskId } from "core/shared/task/useTaskId";

type TTableRecord = IDashboardShiftStatisticTasksByTeamsLkuTask | IGroup<IDashboardShiftStatisticTasksByTeamsLkuTask>;

interface CellProps {
  record: TTableRecord;
}

export function LkuRackAndEquipment({ record }: CellProps): ReactElement {
  const [, setTaskId] = useTaskId();

  if (isGroup(record)) {
    return <div>{record.name}</div>;
  }
  const handleShowTask = () => {
    setTaskId(record.id);
  };

  return (
    <Button
      type="link"
      className="multi-line"
      style={{
        top: -8, // Сместиться на место невидимой иконки для развертывания строк, т.к. она не отображается в этой строке, но место занимает. Иконка была создана в antd Table.
      }}
      onClick={handleShowTask}
    >
      {record.lku_rack || "РШ не указан"}
      <br />
      {record.lku_equipment || "Бокс не указан"}
    </Button>
  );
}
