import { ReactElement } from "react";
import clsx from "clsx";
import qs from "qs";
import { Form } from "antd";
import { Dayjs } from "dayjs";

import { useUnit } from "core/shared/unit/useUnit";
import { useGetDashboardVolsMaintenancePlanFactStatisticQuery } from "core/app/api/VOLS";
import { IVolsMaintenancePlanFactStatisticBySystem } from "core/app/api/VOLS/interfaces";
import { roundTo } from "core/shared/tools/math";
import { VolsEquipmentTypeCodeEnum } from "core/app/api/VOLS/enums";

import { getStatAmount } from "../../VolsMaintenanceStatistic";

interface RowProps {
  title: string;
  data: IVolsMaintenancePlanFactStatisticBySystem[];
  minusData?: IVolsMaintenancePlanFactStatisticBySystem[];
}

function Row({ title, data, minusData }: RowProps) {
  return (
    <tr>
      <td>{title}</td>
      <td>
        {roundTo(
          getStatAmount(data, VolsEquipmentTypeCodeEnum.CROSS) -
            (minusData ? getStatAmount(minusData, VolsEquipmentTypeCodeEnum.CROSS) : 0),
          1
        )}
      </td>
      <td>
        {roundTo(
          getStatAmount(data, VolsEquipmentTypeCodeEnum.LINE) -
            (minusData ? getStatAmount(minusData, VolsEquipmentTypeCodeEnum.LINE) : 0),
          1
        )}
      </td>
    </tr>
  );
}

export function TableVols(): ReactElement {
  const unit = useUnit();
  const yearDate = Form.useWatch("year") as Dayjs;
  const monthDate = Form.useWatch("month") as Dayjs;
  const customerId = Form.useWatch("customer_id");
  const customerDepartmentId = Form.useWatch("customer_department_id");

  const queryParams = {
    unit_id: unit?.id,
    ...(yearDate && { year: yearDate.year() }),
    ...(monthDate && { month: monthDate.month() + 1 }),
    ...(customerId && { customer_id: customerId }),
    ...(customerDepartmentId && { customer_department_id: customerDepartmentId }),
  };
  const { data } = useGetDashboardVolsMaintenancePlanFactStatisticQuery(qs.stringify(queryParams));

  if (!data) {
    return <div />;
  }

  const isCurrentMonth = monthDate?.month() === new Date().getMonth();
  const isCurrentYear = yearDate?.year() === new Date().getFullYear();
  const isCurrentDate = isCurrentMonth && isCurrentYear;

  return (
    <div className={clsx("maintenance-stats")}>
      <table>
        <tbody>
          <tr className="head">
            <td />
            <td width="25%">Кроссы, шт</td>
            <td width="25%">Линии, м</td>
          </tr>
          <Row title="План на месяц" data={data.plans_for_the_month} />
          {isCurrentDate && <Row title="План на тек. дату" data={data.plans_for_the_day} />}
          <Row title="Факт" data={data.facts_for_the_day} />
          <Row title="Остаток" data={data.plans_for_the_month} minusData={data.facts_for_the_day} />
          {isCurrentDate && (
            <Row
              title="Факт минус план на тек. дату"
              data={data.facts_for_the_day}
              minusData={data.plans_for_the_day}
            />
          )}
        </tbody>
      </table>
    </div>
  );
}
