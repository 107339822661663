import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  label?: string;
}
/** @todo отличается только лейблом, надо рефакторить */
function VolsEquipmentType({ task, label }: FieldProps): ReactElement {
  return <Field label={label || "Тип"} value={task.equipment_type?.name} />;
}

export { VolsEquipmentType };
