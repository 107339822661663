import { ReactElement } from "react";
import { Row, Col, Card } from "antd";
import { NavLink } from "react-router-dom";
import qs from "qs";

import { useGetDashboardOpenedTasksStatisticQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import { AppRoutes } from "core/shared/constants.routes";
import Text from "core/lib/Text/Text";

import State from "./State";
import Priority from "./Priority";
import Efforts from "./Efforts";

/** Статистика по АВР. */
export default function AccidentsStatistic(): ReactElement {
  const [unitId] = useUnitId();
  const { data } = useGetDashboardOpenedTasksStatisticQuery(
    qs.stringify({
      unit_id: unitId,
      type: "RECOVERY",
    })
  );

  return (
    <Card>
      <Row className="mb-3">
        <Col>
          <NavLink to={AppRoutes.tasks}>
            <Text className="uppercase navlink" boldness="semibold">
              Текущие данные по АВР
            </Text>
          </NavLink>
        </Col>
      </Row>
      <Row style={{ padding: "0px 16px" }} justify="space-between">
        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="mb-1">
              <Text className="uppercase mb-2" boldness="semibold">
                Всего заявок
              </Text>
            </div>
            <div>
              <Text style={{ lineHeight: "normal" }} boldness="black" size="h2">
                {data?.amount}
              </Text>
              <Text size="body1"> шт.</Text>
            </div>
          </div>
        </Col>
        <Col>
          <State aggregations={data?.state_aggregation} />
        </Col>
        <Col>
          <Priority aggregations={data?.priority_aggregation} />
        </Col>
        <Col>
          <Efforts aggregations={data?.work_time_usage_percent_aggregation} />
        </Col>
      </Row>
    </Card>
  );
}
