import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { formatDuration } from "core/shared/tools/dayjs";
import { EffortTypeEnum } from "core/models/enums";

import { Field } from "../Field";

interface EffortFieldProps {
  task: ITask;
  effortType: EffortTypeEnum;
}

/** Плановые трудозатраты по задаче/смене. Используется в карточке задачи (но не при создании задачи). */
export function EffortField({ task, effortType }: EffortFieldProps): ReactElement {
  const value = effortType === EffortTypeEnum.MAN_HOURS ? `${task.effort} чч.` : formatDuration(task.effort_duration);

  return <Field label="Плановые трудозатраты" value={value} valueClassName="blue" />;
}
