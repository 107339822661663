import { ReactElement } from "react";
import { Radio, Row, Col } from "antd";

import { UrgencyEnum } from "core/app/api/interfaces";
import icCritical from "core/images/urgency-critical.svg";
import icHigh from "core/images/urgency-high.svg";
import icMedium from "core/images/urgency-medium.svg";
import icLow from "core/images/urgency-low.svg";

interface ButtonContentProps {
  title: string;
  iconSrc: string;
}
function ButtonContent({ title, iconSrc }: ButtonContentProps): ReactElement {
  return (
    <Row gutter={8} align="middle" justify="center">
      <Col>
        <img style={{ verticalAlign: "middle" }} src={iconSrc} alt="priority" />
      </Col>
      <Col>{title}</Col>
    </Row>
  );
}

interface TaskUrgencySelectorProps {
  value?: UrgencyEnum;
  onChange?: (value: UrgencyEnum) => void;
}
/**  */
export function TaskUrgencySelector({ value, onChange }: TaskUrgencySelectorProps): ReactElement {
  return (
    <Radio.Group
      style={{ width: "100%" }}
      size="large"
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
    >
      <Radio.Button style={{ width: "25%" }} value={UrgencyEnum.Critical}>
        <ButtonContent title="КРИТИЧНЫЙ" iconSrc={icCritical} />
      </Radio.Button>
      <Radio.Button style={{ width: "25%" }} value={UrgencyEnum.High}>
        <ButtonContent title="ВЫСОКИЙ" iconSrc={icHigh} />
      </Radio.Button>
      <Radio.Button style={{ width: "25%" }} value={UrgencyEnum.Medium}>
        <ButtonContent title="СРЕДНИЙ" iconSrc={icMedium} />
      </Radio.Button>
      <Radio.Button style={{ width: "25%" }} value={UrgencyEnum.Low}>
        <ButtonContent title="НИЗКИЙ" iconSrc={icLow} />
      </Radio.Button>
    </Radio.Group>
  );
}
