import { ReactElement } from "react";
import clsx from "clsx";
import qs from "qs";
import { Form } from "antd";
import { Dayjs } from "dayjs";

import Text from "core/lib/Text/Text";
import { useUnit } from "core/shared/unit/useUnit";
import { useGetDashboardLsoMaintenancePlanFactStatisticQuery } from "core/app/api/LSO";
import { roundTo } from "core/shared/tools/math";

export function TableLso(): ReactElement {
  const unit = useUnit();
  const yearDate = Form.useWatch("year") as Dayjs;
  const monthDate = Form.useWatch("month") as Dayjs;
  const customerId = Form.useWatch("customer_id");
  const customerDepartmentId = Form.useWatch("customer_department_id");

  const queryParams: Record<string, any> = {
    unit_id: unit?.id,
  };

  if (yearDate) {
    queryParams.year = yearDate.year();
  }
  if (monthDate) {
    queryParams.month = monthDate.month() + 1;
  }
  if (customerId) {
    queryParams.customer_id = customerId;
  }
  if (customerDepartmentId) {
    queryParams.customer_department_id = customerDepartmentId;
  }

  const { data } = useGetDashboardLsoMaintenancePlanFactStatisticQuery(qs.stringify(queryParams));

  if (!data) {
    return <div />;
  }

  const isCurrentMonth = monthDate?.month() === new Date().getMonth();
  const isCurrentYear = yearDate?.year() === new Date().getFullYear();
  const isCurrentDate = isCurrentMonth && isCurrentYear;

  const difference = roundTo(data.facts_for_the_day - data.plans_for_the_day, 2);

  return (
    <div className={clsx("maintenance-stats")}>
      <table>
        <tbody>
          <tr>
            <td>План на месяц</td>
            <td>{data.plans_for_the_month} ч.</td>
          </tr>
          {isCurrentDate && (
            <tr>
              <td>План на тек. дату</td>
              <td>{data.plans_for_the_day ? `${data.plans_for_the_day}ч.` : "-"}</td>
            </tr>
          )}
          <tr>
            <td>Факт</td>
            <td>{data.facts_for_the_day} ч.</td>
          </tr>
          <tr>
            <td>Остаток</td>
            <td>
              <span>{roundTo(data.plans_for_the_month - data.facts_for_the_day, 1)} ч.</span>
            </td>
          </tr>
          {isCurrentDate && (
            <tr>
              <td>Факт минус план на тек. дату</td>
              <td>
                <div
                  className={clsx({
                    advance: difference >= 0,
                    lag: difference < 0,
                  })}
                >
                  <div>
                    <Text boldness="black">{difference} ч.</Text>
                  </div>
                  {difference > 0 && (
                    <div>
                      <Text size="label3">с опережением плана</Text>
                    </div>
                  )}
                  {difference < 0 && difference / data.plans_for_the_day < -0.2 && (
                    <div>
                      <Text size="label3">отставание более чем на 20%</Text>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
