import { Drawer, Form, Tabs } from "antd";
import { ReactElement, useEffect } from "react";

import { useGetTaskAttachmentsQuery } from "core/app/api";
import { IEtoTask, ITask, TaskAttachmentCategoryEnum } from "core/app/api/interfaces";
import { getAttachmentsCategoryCount, isPspaZfTask, mediaCategoryTitles } from "core/shared/task";
import { MediaContext, useMediaContextInitialValue } from "core/shared/task/context/mediaContext";

import { MediaBadge } from "./Badge";
import { Category } from "./Category";
import { MediaGallery } from "./MediaGallery";
import { SaveAllBtn } from "./SaveAllBtn";

interface MediaProps {
  task: ITask | IEtoTask;
}

/** Закладки и список медиа-файлов в карточке задания. */
export default function Media({ task }: MediaProps): ReactElement {
  const form = Form.useFormInstance<ITask>();
  const values = Form.useWatch([], form);
  const { data } = useGetTaskAttachmentsQuery(task.id);
  const contextValue = useMediaContextInitialValue();
  const { setTaskId, setSelectedMediaId, selectedMediaId } = contextValue;

  useEffect(() => {
    if (task) {
      setTaskId(task.id);
    }
  }, [setTaskId, task]);

  const items = [
    {
      label: (
        <MediaBadge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.FilesByTask)}>
          {mediaCategoryTitles.files_by_task}
        </MediaBadge>
      ),
      key: "item-0",
      children: <Category task={task} category={TaskAttachmentCategoryEnum.FilesByTask} />,
    },
    {
      label: (
        <MediaBadge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.WorkPlace)}>
          {mediaCategoryTitles.work_place}
        </MediaBadge>
      ),
      key: "item-1",
      children: <Category task={task} category={TaskAttachmentCategoryEnum.WorkPlace} />,
    },
    {
      label: (
        <MediaBadge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.Before)}>
          {mediaCategoryTitles.before_or_in_progress}
        </MediaBadge>
      ),
      key: "item-2",
      children: <Category task={task} category={TaskAttachmentCategoryEnum.Before} />,
    },
    {
      label: (
        <MediaBadge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.After)}>
          {mediaCategoryTitles.after_completion}
        </MediaBadge>
      ),
      key: "item-3",
      children: <Category task={task} category={TaskAttachmentCategoryEnum.After} />,
    },
  ];

  /** @todo Установить тайпгард, чтобы избавиться от хардкода типов */
  if (isPspaZfTask(task)) {
    const hasVandalActions =
      values?.is_vandalism_detected ||
      values?.maintenance_execution?.some((execution) => execution.is_vandalism_detected);

    if (hasVandalActions) {
      items.push({
        label: (
          <MediaBadge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.Vandalism)}>
            {mediaCategoryTitles.vandalism}
          </MediaBadge>
        ),
        key: "item-4",
        children: <Category task={task} category={TaskAttachmentCategoryEnum.Vandalism} />,
      });
    }
  }

  return (
    <MediaContext.Provider value={contextValue}>
      <Tabs className="tabs-with-badges" items={items} tabBarExtraContent={<SaveAllBtn taskId={task.id} />} />
      <Drawer
        open={!!selectedMediaId}
        onClose={() => {
          setSelectedMediaId(null);
        }}
        width="100VW"
        zIndex={2000}
      >
        {data && <MediaGallery attachments={data} />}
      </Drawer>
    </MediaContext.Provider>
  );
}
