import { Col, Form } from "antd";

import { IAttachment, StateNamesEnum, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetTaskStatesQuery } from "core/app/api";
import dayjs, { apiDateFormat, apiDateTimeFormat } from "core/shared/tools/dayjs";
import { IDictionaryItem } from "core/shared/interfaces";
import { useUnit } from "core/shared/unit/useUnit";
import EffortInput from "core/widgets/CreateTaskModal/Fields/EffortInput";
import AttachmentsField from "core/widgets/CreateTaskModal/Fields/AttachmentsField";
import ShiftSelector from "core/widgets/CreateTaskModal/Fields/ShiftSelector";
import WorkTypeField from "core/widgets/CreateTaskModal/Fields/WorkTypeField";
import { ICreateOtherVolsTaskFormValues, TCreateOtherVolsTaskBody } from "core/app/api/VOLS/interfaces";
import { useCreateVolsTaskMutation } from "core/app/api/VOLS";
import { Customer } from "core/widgets/CreateTaskModal/Fields/Customer";
import { CustomerDepartmentField } from "core/widgets/CreateTaskModal/Fields/CustomerDepartment";
import { EffortTypeEnum } from "core/models/enums";

import { CreateTaskBaseForm } from "../../CreateTaskBaseForm";
import { Description } from "../../../Fields/Description";

import { VolsObjectField } from "./Fields/VolsObjectField";
import { VolsLineField } from "./Fields/VolsLineField";

function VolsFormContent() {
  const form = Form.useFormInstance<ICreateOtherVolsTaskFormValues>();
  return (
    <>
      <Col span={24}>
        <WorkTypeField />
      </Col>
      <Col span={12}>
        <Customer />
      </Col>
      <Col span={12}>
        <CustomerDepartmentField
          label="ПОДРАЗДЕЛЕНИЕ"
          name="customerDepartmentId"
          onChange={() => {
            form.setFieldsValue({
              objectId: undefined,
              lineId: undefined,
            });
          }}
        />
      </Col>
      <Col span={12}>
        <VolsObjectField />
      </Col>
      <Col span={12}>
        <VolsLineField />
      </Col>
      <Col span={12}>
        <EffortInput effortType={EffortTypeEnum.HOURS} required />
      </Col>
      <Col span={24}>
        <Description />
      </Col>
      <Col span={24}>
        <AttachmentsField />
      </Col>
      <Col className="mb-1 mt-1" span={24}>
        <ShiftSelector />
      </Col>
    </>
  );
}

export function Vols() {
  const unit = useUnit();
  const [unitId] = useUnitId();
  const [sendTask, sendTaskResult] = useCreateVolsTaskMutation();
  const { data: states } = useGetTaskStatesQuery("");
  const initialValues: ICreateOtherVolsTaskFormValues = {
    effort_duration: "00:00:00",
    description: "",
    attachments: [],
    shiftNumber: 1,
    plannedDate: "",
    teamId: null,
  };

  const createTask = async (values: typeof initialValues) => {
    if (!unitId || !states) {
      return Promise.reject();
    }

    const state = states.results.find((item: IDictionaryItem) => item.name === StateNamesEnum.New);

    const body: TCreateOtherVolsTaskBody = {
      type: TaskTypesIdsEnum.OTHER,
      state_id: state?.id,
      unit_id: unitId,
      service_id: unit?.service.id,
      request_creation_datetime: dayjs().format(apiDateTimeFormat),
      work_type_id: values.workTypeId,
      customer_id: values.customerId,
      customer_department_id: values.customerDepartmentId,
      vols_object_id: values?.objectId,
      line_id: values?.lineId,
      effort_duration: values.effort_duration,
      request_description: values.description,
    };
    if (values.teamId) {
      body.team_id = values.teamId;
    }
    if (values.shiftNumber) {
      body.shift_number = values.shiftNumber;
    }
    if (values.plannedDate) {
      body.planned_date = dayjs(values.plannedDate).format(apiDateFormat);
    }
    if (values.attachments) {
      body.attachments = {
        files_by_task: values.attachments as IAttachment[],
        work_place: [],
        before_or_in_progress: [],
        after_completion: [],
      };
    }

    return sendTask(body).unwrap();
  };

  return (
    <CreateTaskBaseForm
      initialValues={initialValues}
      createTaskFunction={createTask}
      createTaskIsLoading={sendTaskResult.isLoading}
      shouldCancelSubmit={!unitId || !states}
    >
      <VolsFormContent />
    </CreateTaskBaseForm>
  );
}
