import { Table } from "antd";
import { ReactElement } from "react";

import { IDashboardShiftStatisticTasksByTeamsLkuTask } from "core/app/api/LKU/interfaces";
import { EffortTypeEnum } from "core/models/enums";
import { IGroup } from "core/shared/dashboard";
import { useDashboardShiftStatisticTasksByTeamsLku } from "core/shared/dashboard/api/useDashboardShiftStatisticTasksByTeamsLku";

import ClosingNote from "../Fields/ClosingNote";
import { Comment } from "../Fields/Comment";
import Effort from "../Fields/Effort";
import ElapsedTime from "../Fields/ElapsedTime";
import RequestDescription from "../Fields/RequestDescription";

import { LkuDepartment } from "./LkuDepartment";
import { LkuEquipmentType } from "./LkuEquipmentType";
import { LkuObject } from "./LkuObject";
import { LkuRackAndEquipment } from "./LkuRackAndEquipment";

type TTableRecord = IDashboardShiftStatisticTasksByTeamsLkuTask | IGroup<IDashboardShiftStatisticTasksByTeamsLkuTask>;

const columns = [
  {
    title: "РШ+бокс",
    dataIndex: "rack_and_equipment",
    render: (_: string, record: TTableRecord) => <LkuRackAndEquipment record={record} />,
    width: 140,
  },
  {
    title: "Объект",
    dataIndex: "object",
    render: (_: string, record: TTableRecord) => <LkuObject record={record} />,
    width: 140,
  },
  {
    title: "Система",
    dataIndex: "system",
    render: (_: string, record: TTableRecord) => <LkuEquipmentType record={record} />,
    width: 100,
  },
  {
    title: "Подразделение",
    dataIndex: "customer_department",
    render: (_: string, record: TTableRecord) => <LkuDepartment record={record} />,
    width: 100,
  },
  {
    title: "Описание задачи",
    dataIndex: "request_description",
    width: 300,
    render: (_: string, record: TTableRecord) => <RequestDescription record={record} />,
  },
  {
    title: "Статус",
    dataIndex: "status",
  },
  {
    title: "Комментарий",
    dataIndex: "request_comment",
    render: (_: string, record: TTableRecord) => <Comment record={record} />,
  },
  {
    title: "Примечание к разрешению",
    dataIndex: "closing_note",
    render: (_: string, record: TTableRecord) => <ClosingNote record={record} />,
  },
  {
    title: "Загрузка (план)",
    dataIndex: "effort",
    render: (_: string, record: TTableRecord) => <Effort record={record} effortType={EffortTypeEnum.MAN_HOURS} />,
  },
  {
    title: "Загрузка (факт)",
    dataIndex: "elapsed_time",
    render: (_: string, record: TTableRecord) => <ElapsedTime record={record} effortType={EffortTypeEnum.MAN_HOURS} />,
  },
];

export function StatisticByTeamLkuTable(): ReactElement {
  const dataSource = useDashboardShiftStatisticTasksByTeamsLku();

  return (
    <Table
      className="dashboard-byteam-table"
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 0,
      }}
      size="small"
      dataSource={dataSource}
      rowKey="id"
      columns={columns}
    />
  );
}
