import { Row, Col, FormInstance, Form, Input } from "antd";
import { ReactElement } from "react";

import { useDisabled } from "core/shared/task/useDisabled";

import UsedMaterialsTextArea from "../../shared/UsedMaterialsTextArea";
import { ElapsedTime } from "../../shared/ElapsedTime";
import { EmployeesSelect } from "../../shared/EmployeesSelect/FormControlled";

import { Comments } from "./Comments";

const { TextArea } = Input;

interface BodyProps {
  form: FormInstance;
}

function Body({ form }: BodyProps): ReactElement {
  const teamId = form.getFieldValue("team_id");
  const isDisabled = useDisabled();

  return (
    <>
      <Row>
        <Col span={24}>
          <Comments />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="КОММЕНТАРИЙ"
            name="comment"
            required
            rules={[
              {
                validator: (_, value: string) => {
                  if (!value.trim()) {
                    return Promise.reject(new Error("Пожалуйста, введите КОММЕНТАРИЙ"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TextArea rows={5} disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <UsedMaterialsTextArea disabled={isDisabled} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <EmployeesSelect disabled={isDisabled} teamId={teamId} />
        </Col>
        <Col span={12}>
          <ElapsedTime useMultiplier disabled={isDisabled} />
        </Col>
      </Row>
    </>
  );
}

export default Body;
