import { Tabs } from "antd";
import type { TabsProps } from "antd";

import { TaskMediaTable } from "./TaskMediaTable";
import { FormMedia } from "./FormMedia";

export function CreateOpinMedia() {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "НОВЫЕ",
      children: <FormMedia />,
    },
    {
      key: "2",
      label: "ФОТО ИЗ ЗАДАЧИ",
      children: <TaskMediaTable />,
    },
  ];
  return <Tabs defaultActiveKey="1" items={items} />;
}
