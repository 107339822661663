import { Timeline } from "antd";
import { Dayjs } from "dayjs";

import Text from "core/lib/Text/Text";
import dayjs, { displayDateFormat, displayTimeFormat } from "core/shared/tools/dayjs";
import { useTask } from "core/shared/task/useTask";
import { IOpinTask, CommentStateEnum } from "core/app/api/OPIN/interfaces";

import "./styles.scss";

interface LabelProps {
  updatedAt: Dayjs;
}
function Label({ updatedAt }: LabelProps) {
  return (
    <div>
      <Text boldness="semibold" size="label2">
        {updatedAt.format(displayDateFormat)}
      </Text>
      <br />
      <Text boldness="semibold" size="label2">
        {updatedAt.format(displayTimeFormat)}
      </Text>
    </div>
  );
}

export function Comments() {
  const { data: task } = useTask();
  const comments = (task as IOpinTask)?.comments;
  const finalComments = comments?.filter((comment) => comment.state === CommentStateEnum.FINAL);
  return (
    <div className="coments">
      <div className="mb-2">
        <Text size="body2" transform="uppercase" boldness="semibold">
          Предыдущие комментарии
        </Text>
      </div>

      <Timeline mode="left">
        {finalComments?.map((comment) => (
          <Timeline.Item
            style={{ whiteSpace: "pre-line" }}
            key={comment.id}
            label={<Label updatedAt={dayjs(comment.updated_at)} />}
          >
            <Text boldness="semibold" size="label1">
              {comment.user.last_name || comment.user.first_name
                ? `${comment.user.last_name} ${comment.user.first_name}`.trim()
                : "Имя не указано"}
            </Text>
            <br />
            <Text boldness="semibold" size="label2">
              {comment.message}
            </Text>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
}
