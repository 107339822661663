import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { message } from "antd";
import qs, { IStringifyOptions } from "qs";
import { get } from "lodash";
import { mimeWordDecode } from "emailjs-mime-codec";
import { Mutex } from "async-mutex";

import { baseUrl } from "core/shared/constants";
import { IUnit } from "core/shared/unit/types";
import { logOut, setToken } from "core/app/store/token-slice";
import {
  ITask,
  IUpdateTaskCheckListPayload,
  IUpdateTaskStatePayload,
  IUnitYearMonth,
  ICopyMonthPlanPayload,
  ICredentials,
  ITasksStatisticsResponse,
  ITasksStatisticsByShift,
  IUnitShift,
  IWorkingRhythm,
  IResponseBody,
  ITasksStatisticsByTeam,
  IPermissionResponseBody,
  ITransport,
  IEquipment,
  IEmployee,
  IMonthPlan,
  IEquipmentType,
  IDashboardOpenedTasksStatistic,
  IDashboardMaintenancePlanFactStatistic,
  ITeam,
  IAttachment,
  IClosingCode,
  IDelayReason,
  IAddress,
  IYearPlanTransportsStat,
  IPermission,
  ISlpiObjectMonthPlan,
  IShiftPlan,
  IChangeShiftPlanStatusPayload,
  ISlpiObjectStat,
  ICheckMonthPlanPayload,
  ICheckMonthPlanResult,
  IEquipmentsTreeItem,
  IEquipmentMaintenance,
  IEquipmentMaintenanceJournalItem,
  IEquipmentCommentJournalItem,
  ILocation,
  IGroupTasks,
  IDashboardShiftStatistic,
  IDashboardShiftStatisticTasks,
  IDashboardShiftStatisticTasksByEquipmentTypes,
  IDashboardShiftStatisticTasksByTeams,
  TSLPISystemComplex,
  ISlpiDirectObject,
  IVideoHelp,
  IChangeCreateVacationOrSickLeaveDaysPayload,
  ICreateMaintenanceMountedTaskPayload,
  IPrintTasksFormProps,
  IPerfomanceRatingHallOfFame,
  IPerfomanceRatingTree,
  IPerfomanceRatingTasksByShift,
  ILeadsRating,
  IDashboardShiftStatisticTasksByTeamsSlpiTask,
  IDashboardShiftStatisticTasksByTeamsPspaTask,
  IDashboardShiftStatisticTasksByTeamsOossaTask,
  IDashboardShiftStatisticTasksByObjectsSlpi,
  ITeamsShiftTasksReportQueryProps,
  ICreateTaskSignaturePayload,
  ITaskSignature,
  ICustomerRepresentativePayload,
  ICustomerRepresentative,
  IPosition,
  IGetTaskSignatureFileProps,
  IUnitPreferences,
  IRecoveryTasksNotification,
  ISkipRecoveryTasksNotificationBody,
  IPlanTasksNotificationBody,
  IUserSettingsTasksNotifications,
  IItsmCategoryGroup,
  ISendCreatioTaskBody,
  IItsmCategory,
  IWhoami,
  IWorkRegime,
  ICreateMonthPlansPayload,
  ICreateMonthPlansResponse,
  IItsmServiceOfferinge,
  IDailySummaryReport,
  IUpdateDailySummaryReportBody,
  TSlpiDirectObjectLight,
  IDailySummaryReportExist,
  IDashboardSlpiMaintenancePlanFactStatistic,
  ISLPISystemComplexLight,
  IAvailableShiftPlanStatuses,
  IAvailableShiftPlanStatusesQueryPayload,
  IOossaYearPlansQueryPayload,
  IOossaYearPlan,
  IOossaMonthPlan,
  IOossaMonthPlanQueryPayload,
  IPlanByMonth,
  IOossaMonthPlansCreateQueryPayload,
  IUiPermissionBodyPayload,
  IUiPermission,
  IOossaEquipment,
  IApproveOossaMonthPlanPayload,
  IApproveOossaMonthPlan,
  IExtendPreviousMonthQueryPayload,
  ICheckSlpiMonthPlan,
  ITeamTasks,
  IGetTaskQueryPayload,
  IGetTasksQueryPayload,
  ISlpiWorkType,
  INoPhotoReason,
  ICustomerDepartment,
  IUnitDepartment,
  TQueryProps,
  IApproveMonthPlanHistory,
  IApproveMonthPlanHistoryPayload,
  IRepairArea,
  IGetPspaMonthPlanSignatureProps,
  IPspaMonthPlanSignature,
  IUpdatePspaMonthPlanSignatureProps,
  ICreateSlpiMonthPlansPayload,
  IOossaYearPlansStat,
  IOossaObject,
  IOossaTechNode,
  ISystemInterface,
  TOossaMonthPlanState,
  IOossaMaintenancePlanFactStatistic,
  IOossaWorkType,
  IDashboardShiftStatisticTasksByObjectsOossa,
  ISlpiMonthPlansAndEffort,
  TQueryPropsWithStringifyOptions,
  IScheduleStatsEmployees,
  IEtoTask,
  IUpdateEtoStepPayload,
  IEtoMonthPlanStat,
  ICreateEtoMonthPlansPayload,
  IEtoMonthPlan,
  IUpdateEtoMonthPlanPayload,
  ICreateEtoStepSignaturePayload,
  IEquipmentLastProblem,
  IEmployeeSignature,
  IOossaTask,
  ISlpiTask,
  IPspaTask,
  IMaintenanceType,
  ICreatePspaKFPlansPayload,
  ITransportMaintenance,
} from "core/app/api/interfaces";
import type { TRootState } from "core/app/store";
import { IDictionaryItem } from "core/shared/interfaces";

import { ITeamCopyModalState } from "../store/team-copy-modal-slice";

import { TWorkType } from "./types";
import { ApiSliceEnum } from "./enums";
import { handleUpdateTaskQueryStarted, TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE } from "./Tools";

interface IGetTransportStatPayload {
  unit_id: number;
  customer_id: number | null;
  year: number;
  month: number;
}
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as TRootState).token;
    if (token) {
      // include token in req header
      headers.set("authorization", `Bearer ${token.access}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  const { token } = (api.getState() as TRootState).token;
  if (result.error) {
    if (result.error.status === 500) {
      message.error("Ошибка подключения к серверу");
    }
    if (result.error.status === 401 && token) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        // try to get a new token
        const refreshResult = await baseQuery(
          {
            url: "auth/jwt/refresh/",
            method: "POST",
            body: {
              refresh: token.refresh,
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          // store the new token
          api.dispatch(
            setToken({
              ...token,
              ...(refreshResult.data as { access: string }),
            })
          );
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
        release();
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  return result;
};

export const extractResults = <T>(responseData: IResponseBody<T>) => responseData.results;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractShifts = (responseData: any) => responseData[0]?.details;

const getFileNameFromContentDisposition = (contentDisposition: string | null) => {
  if (contentDisposition) {
    const decoded = mimeWordDecode(contentDisposition);
    if (decoded.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(decoded);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, "");
      }
    }
  }
  return null;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "teams",
    "teams/teams-to-copy",
    "team",
    "tasks",
    "units",
    "unit",
    "shifttask",
    "tasks-filtered",
    "year-plan",
    "month-plan",
    "employees",
    "month-plan/check",
    "task",
    "task-states",
    "month-plan/get_initial_plan_period",
    "scheduled-task-count",
    "pspa-kf-scheduled-task-count",
    "transport/get_required_task_count",
    "transport/maintenance",
    "kanban-board",
    "locations",
    "location",
    "repair-areas",
    "systems",
    "system",
    "equipments",
    "horizons",
    "month-schedule",
    "creatio-states",
    "group-tasks",
    "tasks/statistics-by-days",
    "open-tasks",
    "tasks/statistics-by-shifts",
    "tasks/statistics-by-teams",
    "unit-shifts",
    "working-rhythm",
    "month-plan/permission",
    "month-schedule/available-employees",
    "month-schedule/available-locations",
    "need-create-recovery-tasks",
    "dashboard/opened-tasks-statistic",
    "dashboard/maintenance-plan-fact-statistic",
    "task-attachments",
    "month-plan/recovery-tasks-layer",
    "addresses",
    "address",
    "year-plan/transports-stats",
    "auth/permissions",
    "slpi-object",
    "shift-plan",
    "slpi-department",
    "customers",
    "slpi-object/slpi_stat",
    "itsm-groups",
    "equipments/tree",
    "equipment",
    "equipments/maintenance",
    "equipments/maintenance-journal",
    "equipments/comment-journal",
    "dashboard/shift-statistic",
    "dashboard/shift-statistic/tasks",
    "dashboard/shift-statistic/tasks/by-equipment-types",
    "shift-plan/permissions",
    "dashboard/shift-statistic/tasks/by-teams/pspa",
    "dashboard/shift-statistic/tasks/by-teams/slpi",
    "dashboard/shift-statistic/tasks/by-teams/lso",
    "dashboard/shift-statistic/tasks/by-teams/lku",
    "dashboard/shift-statistic/tasks/by-teams/vols",
    "slpi-system-complex",
    "slpi-direct-object",
    "services",
    "video-help",
    "perfomance-rating",
    "perfomance-rating/average-shift-tasks-quantity",
    "perfomance-rating/hall-of-fame",
    "report/perfomance-rating/rating-tree",
    "report/perfomance-rating/tasks-by-shift",
    "report/perfomance-rating/team-leads-rating",
    "report/perfomance-rating/shift-leads-rating",
    "dashboard/shift-statistic/tasks/by-objects/slpi",
    "dashboard/shift-statistic/tasks/by-objects/lso",
    "dashboard/shift-statistic/tasks/by-objects/lku",
    "dashboard/shift-statistic/tasks/by-customer-department/vols",
    "task-attachment",
    "task-signature",
    "customer-representatives",
    "positions",
    "task-signature/file",
    "unit-preferences",
    "notifications/tasks",
    "ui-elements/ui-elements-permissions",
    "user-settings/task-notifications",
    "itsm-category-groups",
    "itsm-request-categories",
    "itsm-stpp-equipment-type",
    "itsm-equipment",
    "work-types",
    "auth/whoami",
    "units/work-regimes",
    "itsm-service-offerings",
    "report/daily-summary",
    "slpi-month-plan/check",
    "slpi-direct-object-light",
    "report/daily-summary-is-exist",
    "dashboard/slpi-maintenance-plan-fact-statistic",
    "dashboard/lso-maintenance-plan-fact-statistic",
    "dashboard/lku-maintenance-plan-fact-statistic",
    "dashboard/vols-maintenance-plan-fact-statistic",
    "slpi-system-complex-light",
    "slpi-department-extended",
    "shift-plan/available-statuses",
    "oossa/task",
    "oossa/objects",
    "oossa/object",
    "oossa/equipments",
    "oossa/equipment",
    "oossa/systems",
    "oossa/system",
    "oossa-month-plans",
    "oossa-year-plans",
    "slpi-work-types",
    "slpi-units",
    "no-photo-reasons",
    "customer-departments",
    "unit-departments",
    "customer-department",
    "approve-month-plan",
    "pspa-month-plan-signature",
    "oossa-year-plans/oossa_stat",
    "manufacturers",
    "oossa-tech-node",
    "oossa-tech-nodes",
    "oossa-year-plan-by-months/month-plan-approval-state",
    "dashboard/oossa-maintenance-plan-fact-statistic",
    "performance-rating/useful-time-factor",
    "oossa-work-types",
    "dashboard/shift-statistic/tasks/by-teams/oossa",
    "dashboard/shift-statistic/tasks/by-objects/oossa",
    "slpi-month-plan/hours-norm",
    "matomo-environment",
    "schedule-stats-employees",
    "eto-month-plans/eto-statistic",
    "eto-month-plans",
    "lso-objects",
    "lku-year-plans",
    "lku-month-plans",
    "pspa-kf-year-plans",
    "pspa-kf-month-plans",
    "pspa-kf-month-stat",
    "maintenance-types",
    "lso-month-plans",
    "lso-systems",
    "lso-objects/stats",
    "maintenance-types/maintenance-cycle",
    "lku-year-plans-by-objects",
    "lku-year-plans/stat",
    "lku-month-plan-status",
    "lku-month-plans/stat",
    "lku-objects",
    "lku-racks",
    "lku-equipments",
    "lku-work-types",
    "vols-cross-month-plans-totals-by-objects",
    "vols-cross-month-plans-stats",
    "vols-month-plan-status",
    "vols-cross-month-plans",
    "vols-object",
    "vols-line",
    "vols-cross",
    "sgps-group-tasks",
    "vols-line-month-plans-stat",
    "vols-line-month-plans/stats-by-months",
    "vols-line-month-plans",
  ],
  endpoints: (builder) => ({
    getYearPlan: builder.query({
      query: (queryString: string) => `year-plan/?${queryString}`,
      providesTags: ["year-plan"],
    }),
    updateYearPlan: builder.mutation({
      query: (plan) => ({
        url: `year-plan/${plan.id}/`,
        method: "PUT",
        body: plan,
      }),
      invalidatesTags: ["year-plan"],
    }),
    getMonthPlan: builder.query<IResponseBody<IMonthPlan>, string>({
      query: (queryString: string) => `month-plan/?${queryString}`,
      providesTags: ["month-plan"],
    }),
    updateMonthPlan: builder.mutation({
      query: (plan) => ({
        url: `month-plan/${plan.id}/`,
        method: "PUT",
        body: plan,
      }),
      async onQueryStarted({ queryString, ...monthPlanUpdate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getMonthPlan", queryString, (cachedMonthPlans) => {
            const cachedMonthPlanToUpdate = cachedMonthPlans.results.find(
              (cachedMonthPlan: IMonthPlan) => cachedMonthPlan.id === monthPlanUpdate.id
            );
            if (cachedMonthPlanToUpdate) {
              Object.assign(cachedMonthPlanToUpdate, monthPlanUpdate);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
      invalidatesTags: ["scheduled-task-count", "month-plan/check"],
    }),
    getEmployees: builder.query({
      query: (queryString: string) => `employees/?${queryString}`,
      providesTags: ["employees"],
    }),
    getTeams: builder.query<ITeam[], string>({
      providesTags: ["teams"],
      query: (queryString: string) => `teams/?${queryString}`,
      transformResponse: extractResults,
    }),
    getTeam: builder.query<ITeam, number | { id: number; query: string }>({
      providesTags: ["team"],
      query: (payload) => {
        if (typeof payload === "number") {
          return `teams/${payload}/`;
        }
        return `teams/${payload.id}/${payload.query}`;
      },
    }),
    getUnits: builder.query<IUnit[], string>({
      query: () => "units/",
      providesTags: ["units"],
      transformResponse: extractResults,
    }),
    getUnit: builder.query<IUnit, number | string>({
      query: (id) => `units/${id}/`,
      providesTags: ["unit"],
    }),
    getTasks: builder.query<IResponseBody<ITask>, TQueryPropsWithStringifyOptions>({
      query: ({ url, stringifyOptions, ...rest }) => {
        const queryString = qs.stringify(rest, stringifyOptions as IStringifyOptions);
        if (url) {
          return `${url}/?${queryString}`;
        }
        return `tasks/?${queryString}`;
      },
      providesTags: ["tasks"],
    }),
    getOpenTasks: builder.query<any, string | IGetTasksQueryPayload>({
      query: (payload) => {
        if (typeof payload === "string") {
          return `tasks/?${payload}`;
        }
        return `${payload.url}/?${payload.queryProps}`;
      },
      providesTags: ["open-tasks"],
    }),

    getEtoTasks: builder.query<IResponseBody<IEtoTask>, string | undefined>({
      query: (queryProps) => `eto-tasks/?${queryProps}`,
      providesTags: ["open-tasks"],
    }),
    getOossaTasks: builder.query<IResponseBody<IOossaTask>, string | undefined>({
      query: (queryProps) => `oossa-tasks/?${queryProps}`,
      providesTags: ["open-tasks"],
    }),
    getSlpiTasks: builder.query<IResponseBody<ISlpiTask>, string | undefined>({
      query: (queryProps) => `slpi-tasks/?${queryProps}`,
      providesTags: ["open-tasks"],
    }),
    getPspaTasks: builder.query<IResponseBody<IPspaTask>, string | undefined>({
      query: (queryProps) => `pspa-tasks/?${queryProps}`,
      providesTags: ["open-tasks"],
    }),
    getPspaWithEtoTasks: builder.query<IResponseBody<IPspaTask | IEtoTask>, string | undefined>({
      query: (queryProps) => `pspa-tasks-aggregate/?${queryProps}`,
      providesTags: ["open-tasks"],
    }),

    getTask: builder.query<ITask, string | number | IGetTaskQueryPayload>({
      query: (payload) => {
        if (typeof payload === "string" || typeof payload === "number") {
          return `tasks/${payload}/`;
        }
        return `${payload.url}/${payload.taskId}/${payload?.query ?? ""}`;
      },
      providesTags: (result, error, payload) => {
        if (typeof payload === "string" || typeof payload === "number") {
          return [{ type: "task", id: payload }];
        }
        return [{ type: "task", id: payload.taskId }];
      },
    }),
    getEtoTask: builder.query<IEtoTask, string | number>({
      query: (taskId) => `eto-tasks/${taskId}/`,
      providesTags: (result, error, taskId) => [{ type: "task", id: taskId }],
    }),
    getTasksFiltered: builder.query<IResponseBody<ITask>, string>({
      query: (queryString: string) => `tasks/?${queryString}`,
      providesTags: ["tasks-filtered"],
    }),
    getShiftTasks: builder.query<ITeamTasks[], string>({
      query: (queryString: string) => `shifttask/?${queryString}`,
      providesTags: ["shifttask"],
      transformResponse: extractResults,
    }),
    getEquipmentTypes: builder.query<IResponseBody<IEquipmentType>, string>({
      query: (queryString: string) => `equipment-types/?${queryString}`,
    }),
    getEquipmentType: builder.query<IEquipmentType, string | number>({
      query: (id: string | number) => `equipment-types/${id}/`,
    }),
    getItsmEquipmentTypes: builder.query<IResponseBody<IDictionaryItem>, string>({
      query: (queryString: string) => `itsm-equipment-types/?${queryString}`,
    }),
    getItsmEquipment: builder.query<IDictionaryItem[], string>({
      query: (queryString: string) => `itsm-equipment-type-elements/?limit=1000&${queryString}`,
      transformResponse: extractResults,
    }),
    getItsmEquipmentMalfunction: builder.query<IDictionaryItem[], string>({
      query: (queryString: string) => `itsm-equipment-type-malfunction/?limit=1000&${queryString}`,
      transformResponse: extractResults,
    }),
    getItsmEquipmentMalfunctionReason: builder.query<IDictionaryItem[], string>({
      query: (queryString: string) => `itsm-equipment-type-malfunction-reason/?${queryString}`,
      transformResponse: extractResults,
    }),
    getItsmDelayReasons: builder.query<IDelayReason[], string>({
      query: (slice: string) => {
        if (slice) {
          return `${slice}-itsm-delay-reasons/?is_deactivated=false`;
        }
        return `itsm-delay-reasons/`;
      },
      transformResponse: extractResults,
    }),
    getItsmClosingCodes: builder.query<IClosingCode[], string>({
      query: (queryString: string) => `itsm-closing-codes/?${queryString}`,
      transformResponse: extractResults,
    }),

    updateEtoTask: builder.mutation({
      query: ({ rtkQueryCacheKey, ...task }) => ({
        url: `eto-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted(args, queryContext) {
        await handleUpdateTaskQueryStarted(args, queryContext);
      },
      invalidatesTags: (result, error, arg) => [{ type: "task", id: arg.id }, ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE],
    }),
    updatePspaTask: builder.mutation({
      query: ({ rtkQueryCacheKey, ...task }) => ({
        url: `pspa-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted(args, queryContext) {
        await handleUpdateTaskQueryStarted(args, queryContext);
      },
      invalidatesTags: (result, error, arg) => [{ type: "task", id: arg.id }, ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE],
    }),
    updateSlpiTask: builder.mutation({
      query: ({ rtkQueryCacheKey, ...task }) => ({
        url: `slpi-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted(args, queryContext) {
        await handleUpdateTaskQueryStarted(args, queryContext);
      },
      invalidatesTags: (result, error, arg) => [{ type: "task", id: arg.id }, ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE],
    }),

    createTeam: builder.mutation({
      query: (team) => ({
        url: `teams/`,
        method: "POST",
        body: team,
      }),
      invalidatesTags: ["teams", "shifttask", "employees", "shift-plan/available-statuses"],
    }),
    deleteTeam: builder.mutation({
      query: (id) => ({
        url: `teams/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["teams", "shifttask", "employees", "tasks", "tasks-filtered", "shift-plan/available-statuses"],
    }),
    disbandTeam: builder.mutation({
      query: (payload) => ({
        url: `teams/${payload}/disband/`,
        method: "POST",
        body: payload.body,
      }),
      invalidatesTags: [
        "teams",
        "shifttask",
        "employees",
        "tasks",
        "tasks-filtered",
        "shift-plan/available-statuses",
        "kanban-board",
      ],
    }),
    updateTeam: builder.mutation({
      query: (team) => ({
        url: `teams/${team.id}/`,
        method: "PUT",
        body: team,
      }),
      invalidatesTags: ["teams", "shifttask", "employees"],
    }),
    createTeams: builder.mutation({
      query: (teams) => ({
        url: `/teams/bulk-create/`,
        method: "POST",
        body: teams,
      }),
      invalidatesTags: ["teams"],
    }),
    createMonthPlanTasks: builder.mutation({
      query: (payload) => ({
        url: `month-plan/approve/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        "teams",
        "shifttask",
        "tasks",
        "tasks-filtered",
        "year-plan",
        "approve-month-plan",
        "month-plan/check",
      ],
    }),
    createTask: builder.mutation({
      query: (payload) => ({
        url: `tasks/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tasks", "open-tasks", "kanban-board", "tasks-filtered", "shifttask"],
    }),
    createSlpiTask: builder.mutation({
      query: (payload) => ({
        url: `slpi-tasks/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tasks", "open-tasks", "tasks-filtered", "kanban-board", "shifttask"],
    }),
    createOossaTask: builder.mutation({
      query: (payload) => ({
        url: `oossa-tasks/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tasks", "open-tasks", "tasks-filtered", "kanban-board"],
    }),
    getCheckMonthPlan: builder.query<ICheckMonthPlanResult, ICheckMonthPlanPayload>({
      query: (payload) => `month-plan/check/?${qs.stringify(payload)}`,
      providesTags: ["month-plan/check"],
    }),
    getTransportStat: builder.mutation({
      query: (payload: IGetTransportStatPayload) => ({
        url: `transport/stat/`,
        method: "POST",
        body: payload,
      }),
    }),
    monthPlanFindOrCreateTask: builder.mutation<{ task_id: number | null }, any>({
      query: (payload) => ({
        url: `month-plan/find-or-create-task/`,
        method: "POST",
        body: payload,
      }),
    }),
    updateTaskCheckList: builder.mutation({
      query: (payload: IUpdateTaskCheckListPayload) => ({
        url: `tasks/${payload.taskId}/maintenance-execution/${payload.maintenanceExecution}/checklist/${payload.checklist}/`,
        method: "PATCH",
        body: {
          value: payload.value,
        },
      }),
      invalidatesTags: ["task"],
    }),
    monthPlanUndoApprove: builder.mutation({
      query: (payload: ICheckMonthPlanPayload) => ({
        url: `month-plan/undo_approve/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["year-plan", "month-plan", "teams", "shifttask", "tasks", "tasks-filtered", "month-plan/check"],
    }),
    getTaskStates: builder.query<IResponseBody<IDictionaryItem>, string>({
      query: () => `states/`,
      providesTags: ["task-states"],
    }),
    updateTaskState: builder.mutation({
      query: (payload: IUpdateTaskStatePayload) => ({
        url: `tasks/${payload.taskId}/`,
        method: "PATCH",
        body: {
          state_id: payload.stateId,
        },
      }),
      invalidatesTags: ["task", "kanban-board"],
    }),
    makeMonthPlanSchedule: builder.mutation({
      query: (payload: IUnitYearMonth) => ({
        url: `month-plan/make_schedule/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["month-plan", "scheduled-task-count"],
    }),
    copyMonthPlan: builder.mutation({
      query: (payload: ICopyMonthPlanPayload) => ({
        url: `month-plan/copy/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["month-plan", "scheduled-task-count"],
    }),
    getMonthPlanInitialPeriod: builder.query({
      query: (queryString: string) => `month-plan/get_initial_plan_period/?${queryString}`,
      providesTags: ["month-plan/get_initial_plan_period"],
    }),
    getTransport: builder.query<IResponseBody<ITransport>, string>({
      query: (queryString: string) => `transport/?${queryString}`,
    }),
    getTransportMaintenance: builder.query<ITransportMaintenance, string>({
      query: (queryString: string) => `transport/${queryString}/maintenance/`,
      providesTags: ["transport/maintenance"],
    }),
    getScheduledTaskCount: builder.query({
      query: (queryString: string) => `month-plan/get_scheduled_task_count/?${queryString}`,
      providesTags: ["scheduled-task-count"],
    }),
    getPspaKFMonthPlansScheduledTaskCount: builder.query({
      query: (queryString: string) => `pspa-kf-month-plans/get_scheduled_task_count/?${queryString}`,
      providesTags: ["pspa-kf-scheduled-task-count"],
    }),
    resetTask: builder.query({
      query: (taskId: number | null) => `tasks/${taskId}/reset/`,
    }),
    getRequiredTaskCount: builder.query({
      query: (queryString: string) => `transport/get_required_task_count/?${queryString}`,
      providesTags: ["transport/get_required_task_count"],
    }),
    getKanbanBoard: builder.query({
      query: (teamId: string | number) => `kanban-board/${teamId}`,
      providesTags: ["kanban-board"],
    }),
    getLocations: builder.query<IResponseBody<ILocation>, string>({
      query: (queryString: string) => `locations/?${queryString}`,
      providesTags: ["locations"],
    }),
    getLocation: builder.query<ILocation, number>({
      query: (id) => `locations/${id}/`,
      providesTags: ["location"],
    }),
    getRepairAreas: builder.query<IRepairArea[], string>({
      query: (queryString) => `repair-areas/?${queryString}`,
      providesTags: ["repair-areas"],
      transformResponse: extractResults,
    }),
    getSystems: builder.query<ISystemInterface[], string>({
      query: (queryString: string) => `systems/?${queryString}`,
      providesTags: ["systems"],
      transformResponse: extractResults,
    }),
    getSystem: builder.query<ISystemInterface, number>({
      query: (id) => `systems/${id}/`,
      providesTags: ["system"],
    }),
    getEquipments: builder.query<IResponseBody<IEquipment>, string>({
      query: (queryString: string) => `equipments/?${queryString}`,
      providesTags: ["equipments"],
    }),
    getEquipment: builder.query<IEquipment, string>({
      query: (id: string) => `equipments/${id}`,
      providesTags: ["equipment"],
    }),
    getEquipmentLastProblem: builder.query<IEquipmentLastProblem, number>({
      query: (id: number) => `equipments/${id}/last-problem`,
      providesTags: ["equipment"],
    }),
    getEquipmentsLastProblems: builder.query<Record<string, IEquipmentLastProblem>, string>({
      query: (idsCommaSeparated: string) => `equipments/last-problems/?equipments=${idsCommaSeparated}`,
      providesTags: ["equipment"],
    }),
    getHorizons: builder.query({
      query: (queryString: string) => `horizons/?${queryString}`,
      providesTags: ["horizons"],
    }),
    createToken: builder.mutation({
      query: (payload: ICredentials) => ({
        url: `auth/jwt/create/`,
        method: "POST",
        body: payload,
      }),
    }),
    getActByPeriod: builder.mutation({
      query(queryString: string) {
        return {
          url: `tasks/print-act-by-period/?${queryString}`,
          method: "GET",
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: "no-cache",
        };
      },
    }),
    getAct: builder.mutation({
      query(taskId: number) {
        return {
          url: `tasks/${taskId}/print-act/`,
          method: "GET",
          responseHandler: async (response) => {
            const disposition = await response.headers.get("content-disposition");
            const fileName = getFileNameFromContentDisposition(disposition);
            const fileLink = document.createElement("a");
            fileLink.href = window.URL.createObjectURL(await response.blob());
            fileLink.download = fileName ?? `report_${taskId}`;
            fileLink.click();
          },
          cache: "no-cache",
        };
      },
    }),
    getMedia: builder.query({
      query(arg: { taskId: number; attachmentId: number }) {
        return {
          url: `/tasks/${arg.taskId}/attachments/${arg.attachmentId}/download/`,
          method: "GET",
          responseHandler: async (response) => {
            const fileLink = document.createElement("a");
            fileLink.href = window.URL.createObjectURL(await response.blob());
            return fileLink.href;
          },
        };
      },
      providesTags: ["task-attachment"],
    }),
    importTasks: builder.mutation({
      query: (payload) => ({
        url: `tasks/import-tasks/`,
        method: "POST",
        body: payload,
        cache: "no-cache",
      }),
      invalidatesTags: ["tasks", "tasks-filtered"],
    }),
    importSLPITasks: builder.mutation({
      query: (payload) => ({
        url: `slpi-month-plan/import-tasks/`,
        method: "POST",
        body: payload,
        cache: "no-cache",
      }),
      invalidatesTags: ["tasks", "tasks-filtered"],
    }),
    getMonthPlanPrint: builder.mutation({
      query(queryString: string) {
        return {
          url: `month-plan/print/?${queryString}`,
          method: "GET",
          responseHandler: async (response) => {
            if (response.ok) {
              const fileLink = document.createElement("a");
              fileLink.href = window.URL.createObjectURL(await response.blob());
              fileLink.target = "blank";
              const disposition = await response.headers.get("content-disposition");
              const unsafeFilename = getFileNameFromContentDisposition(disposition);
              fileLink.download = unsafeFilename || "month-plan.xls";
              fileLink.click();
            }
          },
          cache: "no-cache",
        };
      },
    }),
    getMonthSchedule: builder.query({
      query: (queryString: string) => `month-schedule/?${queryString}`,
      providesTags: ["month-schedule"],
    }),
    getCreatioStates: builder.query({
      query: () => `tasks/request-statuses/`,
      providesTags: ["creatio-states"],
    }),
    getGroupTasks: builder.query<IGroupTasks[], string>({
      query: (queryString: string) => `tasks/group-tasks/?${queryString}`,
      providesTags: ["group-tasks"],
    }),
    getTasksStatisticsByDays: builder.query<ITasksStatisticsResponse, string>({
      query: (queryString: string) => `tasks/statistics-by-days/?${queryString}`,
      providesTags: ["tasks/statistics-by-days"],
    }),
    getTasksStatisticsByShifts: builder.query<ITasksStatisticsByShift[], string>({
      query: (queryString: string) => `tasks/statistics-by-shifts/?${queryString}`,
      providesTags: ["tasks/statistics-by-shifts"],
    }),
    getTasksStatisticsByTeams: builder.query<ITasksStatisticsByTeam[][], string>({
      query: (queryString: string) => `tasks/statistics-by-teams/?${queryString}`,
      providesTags: ["tasks/statistics-by-teams"],
    }),
    createMonthSchedule: builder.mutation({
      query(body) {
        return {
          url: `month-schedule/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["month-schedule", "employees"],
    }),
    getUnitShifts: builder.query<IUnitShift[], string>({
      query: (queryString: string) => `unit-shifts/?${queryString}`,
      providesTags: ["unit-shifts"],
      transformResponse: extractResults,
    }),
    getWorkingRhythm: builder.query<IResponseBody<IWorkingRhythm>, string>({
      query: (queryString: string) => `working-rhythm/?${queryString}`,
      providesTags: ["working-rhythm"],
    }),
    createGroup: builder.mutation({
      query(body) {
        return {
          url: `/month-schedule/${body.monthScheduleId}/groups/`,
          method: "POST",
          body: { group: body.group },
        };
      },
      invalidatesTags: [
        "month-schedule",
        "month-schedule/available-locations",
        "month-schedule/available-employees",
        "employees",
        "schedule-stats-employees",
      ],
    }),
    updateGroup: builder.mutation({
      query(body) {
        return {
          url: `/month-schedule/${body.monthScheduleId}/groups/${body.groupId}/`,
          method: "PATCH",
          body: { group: body.group },
        };
      },
      invalidatesTags: [
        "month-schedule",
        "month-schedule/available-locations",
        "month-schedule/available-employees",
        "employees",
        "schedule-stats-employees",
      ],
    }),
    deleteGroup: builder.mutation({
      query(body: { monthScheduleId: number; groupId: number }) {
        return {
          url: `/month-schedule/${body.monthScheduleId}/groups/${body.groupId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["month-schedule", "employees", "schedule-stats-employees"],
    }),
    getMonthPlanPermission: builder.query<IPermissionResponseBody, string>({
      query: (queryString: string) => `month-plan/permission/?${queryString}`,
      providesTags: ["month-plan/permission"],
    }),
    getMontScheduleAvailableEmployees: builder.query<IResponseBody<IEmployee>, string>({
      query: (monthScheduleId: string) => `month-schedule/${monthScheduleId}/available-employees/`,
      providesTags: ["month-schedule/available-employees"],
    }),
    getMontScheduleAvailableLocations: builder.query<IResponseBody<IDictionaryItem>, string>({
      query: (monthScheduleId: string) => `month-schedule/${monthScheduleId}/available-locations/`,
      providesTags: ["month-schedule/available-locations"],
    }),
    createRecoveryTasks: builder.mutation({
      query: (taskId: number) => ({
        url: `tasks/${taskId}/create-recovery-tasks/`,
        method: "GET",
      }),
      invalidatesTags: ["month-plan/recovery-tasks-layer", "tasks"],
    }),
    needCreateRecoveryTasks: builder.query<{ need_create: boolean }, number | null>({
      query: (taskId: number) => ({
        url: `tasks/${taskId}/need-create-recovery-tasks/`,
      }),
      providesTags: ["need-create-recovery-tasks"],
    }),
    getDashboardOpenedTasksStatistic: builder.query<IDashboardOpenedTasksStatistic, string>({
      query: (queryString: string) => ({
        url: `dashboard/opened-tasks-statistic/?${queryString}`,
      }),
      providesTags: ["dashboard/opened-tasks-statistic"],
    }),
    getDashboardMaintenancePlanFactStatistic: builder.query<IDashboardMaintenancePlanFactStatistic, string>({
      query: (queryString: string) => ({
        url: `dashboard/maintenance-plan-fact-statistic/?${queryString}`,
      }),
      providesTags: ["dashboard/maintenance-plan-fact-statistic"],
    }),
    getTaskAttachments: builder.query<IAttachment[], number | IGetTaskQueryPayload>({
      query: (payload) => {
        if (typeof payload === "number") {
          return `tasks/${payload}/attachments/`;
        }
        return `${payload.url}/${payload.taskId}/attachments`;
      },
      providesTags: ["task-attachments"],
    }),
    uploadAttachment: builder.mutation({
      query: (payload) => ({
        url: `tasks/${payload.taskId}/attachments/`,
        method: "POST",
        body: payload.body,
      }),
      invalidatesTags: ["task-attachments", "task-attachment"],
    }),
    deleteAttachment: builder.mutation({
      query: (payload) => ({
        url: `tasks/${payload.taskId}/attachments/${payload.attachmentId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["task-attachments", "task-attachment"],
    }),
    setAttachmentShouldBeSent: builder.mutation({
      query: (payload) => ({
        url: `tasks/${payload.taskId}/attachments/${payload.attachmentId}/set_should_be_sent/`,
        method: "POST",
        body: {
          should_be_sent: payload.shouldBeSent,
        },
      }),
      invalidatesTags: ["task-attachments", "task-attachment"],
    }),
    createGroupScheduledDay: builder.mutation({
      query: (payload) => ({
        url: `month-schedule/${payload.monthScheduleId}/groups/${payload.groupId}/create_scheduled_day/`,
        method: "POST",
        body: payload.body,
      }),
      invalidatesTags: ["month-schedule", "employees"],
    }),
    deleteGroupScheduledDay: builder.mutation({
      query: (payload) => ({
        url: `month-schedule/${payload.monthScheduleId}/groups/${payload.groupId}/delete_scheduled_day/`,
        method: "POST",
        body: payload.body,
      }),
      invalidatesTags: ["month-schedule", "employees"],
    }),
    createEmployeeScheduledDay: builder.mutation({
      query: (payload) => ({
        url: `month-schedule/${payload.monthScheduleId}/groups/${payload.groupId}/employees/${payload.employeeId}/create_scheduled_day/`,
        method: "POST",
        body: payload.body,
      }),
      invalidatesTags: ["month-schedule", "employees"],
    }),
    deleteEmployeeScheduledDay: builder.mutation({
      query: (payload) => ({
        url: `month-schedule/${payload.monthScheduleId}/groups/${payload.groupId}/employees/${payload.employeeId}/delete_scheduled_day/`,
        method: "POST",
        body: payload.body,
      }),
      invalidatesTags: ["month-schedule", "employees"],
    }),
    getAddresses: builder.query<IAddress[], string>({
      query: (queryString: string) => `addresses/?${queryString}`,
      providesTags: ["addresses"],
      transformResponse: extractResults,
    }),
    getAddress: builder.query<IAddress, number>({
      query: (id) => `addresses/${id}/`,
      providesTags: ["address"],
    }),
    getYearPlanTransportsStats: builder.query<IYearPlanTransportsStat[], string>({
      query: (queryString: string) => `year-plan/transports-stats/?${queryString}`,
      providesTags: ["year-plan/transports-stats"],
    }),
    makeStationaryEquipmentSchedule: builder.mutation({
      query: (payload) => ({
        url: `month-plan/make_stationary_equipment_schedule/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["month-plan"],
    }),
    getPermissions: builder.query<IPermission, string>({
      query: (queryString: string) => `auth/permissions/?${queryString}`,
      providesTags: ["auth/permissions"],
    }),
    getSlpiObjectsMonthPlans: builder.query<ISlpiObjectMonthPlan[], string>({
      query: (queryString: string) => `slpi-object/?${queryString}`,
      providesTags: ["slpi-object"],
      transformResponse: extractResults,
    }),
    getSlpiDirectObjects: builder.query<ISlpiDirectObject[], string>({
      query: (queryString: string) => `slpi-direct-object/?${queryString}`,
      providesTags: ["slpi-direct-object"],
      transformResponse: extractResults,
    }),
    getSlpiDirectObjectsLight: builder.query<TSlpiDirectObjectLight[], string>({
      query: (queryString: string) => `slpi-direct-object-light/?${queryString}`,
      providesTags: ["slpi-direct-object-light"],
      transformResponse: extractResults,
    }),
    updateSlpiMonthPlan: builder.mutation({
      query(body) {
        return {
          url: `/slpi-month-plan/${body.id}/`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["slpi-object", "slpi-object/slpi_stat", "slpi-month-plan/check"],
    }),
    getShiftPlan: builder.query<IShiftPlan[], string>({
      query: (queryString: string) => `/shift-plan/?${queryString}`,
      providesTags: ["shift-plan"],
    }),
    getAvailableShiftPlanStatuses: builder.query<IAvailableShiftPlanStatuses, IAvailableShiftPlanStatusesQueryPayload>({
      query(payload: IAvailableShiftPlanStatusesQueryPayload) {
        const queryString = qs.stringify(payload);
        return `/shift-plan/available-statuses/?${queryString}`;
      },
      providesTags: ["shift-plan/available-statuses"],
    }),
    getShiftPlanPermissions: builder.query<string[], string>({
      query: (queryString: string) => `/shift-plan/permissions/?${queryString}`,
      providesTags: ["shift-plan/permissions"],
    }),
    changeShiftPlanStatus: builder.mutation({
      query: (payload: IChangeShiftPlanStatusPayload) => ({
        url: `shift-plan/change-status/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        "shift-plan",
        "dashboard/shift-statistic/tasks",
        "dashboard/shift-statistic",
        "task",
        "shift-plan/available-statuses",
      ],
    }),
    getCustomers: builder.query<IDictionaryItem[], string>({
      query: (queryString: string) => `/customer/?${queryString}`,
      providesTags: ["customers"],
      transformResponse: extractResults,
    }),
    checkSlpiMonthPlan: builder.query<ICheckSlpiMonthPlan, string>({
      query: (queryString: string) => `slpi-month-plan/check/?${queryString}`,
      providesTags: ["slpi-month-plan/check"],
    }),
    approveSlpiMonthPlan: builder.mutation({
      query: (queryString: string) => ({
        url: `slpi-month-plan/approve/?${queryString}`,
        method: "GET",
      }),
      invalidatesTags: ["slpi-object"],
    }),
    undoApproveSlpiMonthPlan: builder.mutation({
      query: (queryString: string) => ({
        url: `slpi-month-plan/undo_approve/?${queryString}`,
        method: "GET",
      }),
      invalidatesTags: ["slpi-object"],
    }),
    getSlpiObjectStat: builder.query<ISlpiObjectStat, string>({
      query: (queryString: string) => `slpi-object/slpi_stat/?${queryString}`,
      providesTags: ["slpi-object/slpi_stat"],
    }),
    getResponsibleGroups: builder.query<IDictionaryItem[], string>({
      query: (queryString: string) => `itsm-groups/?${queryString}`,
      providesTags: ["itsm-groups"],
      transformResponse: extractResults,
    }),
    getEquipmentsTree: builder.query<IEquipmentsTreeItem[], string>({
      query: (queryString: string) => `equipments/tree/?${queryString}`,
      providesTags: ["equipments/tree"],
    }),
    getEquipmentMaintenance: builder.query<IEquipmentMaintenance, string>({
      query: (queryString: string) => `equipments/${queryString}/maintenance/`,
      providesTags: ["equipments/maintenance"],
    }),
    getEquipmentMaintenanceJournal: builder.query<IEquipmentMaintenanceJournalItem[], string>({
      query: (queryString: string) => `equipments/${queryString}/maintenance-journal/`,
      providesTags: ["equipments/maintenance-journal"],
    }),
    getEquipmentCommentJournal: builder.query<IEquipmentCommentJournalItem[], string>({
      query: (queryString: string) => `equipments/${queryString}/comment-journal/`,
      providesTags: ["equipments/comment-journal"],
    }),
    getDashboardShiftStatistic: builder.query<IDashboardShiftStatistic, string>({
      query: (queryString: string) => `dashboard/shift-statistic/?${queryString}`,
      providesTags: ["dashboard/shift-statistic"],
    }),
    getDashboardShiftStatisticTasks: builder.query<IDashboardShiftStatisticTasks, string>({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks"],
    }),
    getDashboardShiftStatisticTasksByEquipmentTypes: builder.query<
      IDashboardShiftStatisticTasksByEquipmentTypes,
      string
    >({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/by-equipment-types/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks/by-equipment-types"],
    }),
    getDashboardShiftStatisticTasksByObjectsSlpi: builder.query<IDashboardShiftStatisticTasksByObjectsSlpi, string>({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/by-objects/slpi/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks/by-objects/slpi"],
    }),
    getDashboardShiftStatisticTasksByObjectsOossa: builder.query<IDashboardShiftStatisticTasksByObjectsOossa, string>({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/by-objects/oossa/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks/by-objects/oossa"],
    }),
    getDashboardShiftStatisticTasksByTeamsPspa: builder.query<
      IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsPspaTask>[],
      string
    >({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/pspa/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks/by-teams/pspa"],
    }),
    getDashboardShiftStatisticTasksByTeamsOossa: builder.query<
      IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsOossaTask>[],
      string
    >({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/oossa/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks/by-teams/oossa"],
    }),
    getDashboardShiftStatisticTasksByTeamsSlpi: builder.query<
      IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsSlpiTask>[],
      string
    >({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/slpi/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks/by-teams/slpi"],
    }),
    getSlpiSystemComplex: builder.query<TSLPISystemComplex[], string>({
      query: (queryString: string) => `slpi-system-complex/?${queryString}`,
      providesTags: ["slpi-system-complex"],
      transformResponse: extractResults,
    }),
    getSlpiSystemComplexLight: builder.query<ISLPISystemComplexLight[], string>({
      query: (queryString: string) => `slpi-system-complex-light/?${queryString}`,
      providesTags: ["slpi-system-complex-light"],
      transformResponse: extractResults,
    }),
    getServices: builder.query<IDictionaryItem[], string>({
      query: (queryString: string) => `services/?${queryString}`,
      providesTags: ["services"],
      transformResponse: extractResults,
    }),
    getVideoHelp: builder.query<IVideoHelp[], string>({
      query: () => `video-help/`,
      providesTags: ["video-help"],
      transformResponse: extractResults,
    }),
    getVideoHelpFile: builder.mutation({
      query(id: number) {
        return {
          url: `/video-help/${id}/download/`,
          method: "GET",
          responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        };
      },
    }),
    createVacationOrSickLeaveDays: builder.mutation<string, IChangeCreateVacationOrSickLeaveDaysPayload>({
      query(payload: IChangeCreateVacationOrSickLeaveDaysPayload) {
        const { monthScheduleId, groupId, id, startDate, endDate, scheduleDayType } = payload;
        return {
          url: `month-schedule/${monthScheduleId}/groups/${groupId}/employees/${id}/create_other_types_days/`,
          method: "POST",
          body: {
            start_date: startDate,
            end_date: endDate,
            day_type: scheduleDayType,
          },
        };
      },
      invalidatesTags: ["month-schedule"],
    }),
    createMaintenanceMountedTask: builder.mutation<string, ICreateMaintenanceMountedTaskPayload>({
      query(payload: ICreateMaintenanceMountedTaskPayload) {
        return {
          url: "tasks/create-maintenance-mounted-task/",
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["tasks", "kanban-board", "shifttask"],
    }),
    getPrintTasksForm: builder.query<string, IPrintTasksFormProps>({
      query(props: IPrintTasksFormProps) {
        const { queryProps, unitsNames } = props;
        return {
          url: `tasks/print-tasks-form/?${qs.stringify(queryProps, { indices: false })}`,
          responseHandler: async (response) => {
            if (response.ok) {
              const fileLink = document.createElement("a");
              fileLink.href = window.URL.createObjectURL(await response.blob());
              fileLink.target = "blank";
              const unsafeFilename = `${unitsNames?.join(" ")} ${queryProps.date_from} - ${queryProps.date_to}`;
              fileLink.download = unsafeFilename.replace(".", "");
              fileLink.click();
            }
          },
        };
      },
    }),
    getPerfomanceRatingAverageShiftTasksQuantity: builder.query<number, TQueryProps>({
      query: ({ slice, ...rest }: TQueryProps) =>
        `report/performance-rating/${slice}-average-shift-tasks-quantity/?${qs.stringify(rest)}`,
      providesTags: ["perfomance-rating/average-shift-tasks-quantity"],
    }),
    getPerformanceRatingUsefulTimeFactor: builder.query<number, TQueryProps>({
      query: ({ slice, ...rest }: TQueryProps) =>
        `report/performance-rating/${slice}-useful-time-factor/?${qs.stringify(rest)}`,
      providesTags: ["performance-rating/useful-time-factor"],
    }),
    getPerfomanceRatingHallOfFame: builder.query<IPerfomanceRatingHallOfFame, TQueryProps>({
      query: ({ slice, ...rest }: TQueryProps) =>
        `report/performance-rating/${slice}-hall-of-fame/?${qs.stringify(rest)}`,
      providesTags: ["perfomance-rating/hall-of-fame"],
    }),
    getPerfomanceRatingTree: builder.query<IPerfomanceRatingTree[], TQueryProps>({
      query: ({ slice, ...rest }: TQueryProps) =>
        `report/performance-rating/${slice}-rating-tree/?${qs.stringify(rest)}`,
      providesTags: ["report/perfomance-rating/rating-tree"],
    }),
    getPerfomanceRatingTasksByShift: builder.query<IPerfomanceRatingTasksByShift[], TQueryProps>({
      query: ({ slice, ...rest }: TQueryProps) =>
        `report/performance-rating/${slice}-tasks-by-shift/?${qs.stringify(rest)}`,
      providesTags: ["report/perfomance-rating/tasks-by-shift"],
    }),
    getPerfomanceRatingTeamLeadsRating: builder.query<ILeadsRating[], TQueryProps>({
      query: ({ slice, ...rest }: TQueryProps) =>
        `report/performance-rating/${slice}-team-leads-rating/?${qs.stringify(rest)}`,
      providesTags: ["report/perfomance-rating/team-leads-rating"],
    }),
    getPerfomanceRatingShiftLeadsRating: builder.query<ILeadsRating[], TQueryProps>({
      query: ({ slice, ...rest }: TQueryProps) =>
        `report/performance-rating/${slice}-shift-leads-rating/?${qs.stringify(rest)}`,
      providesTags: ["report/perfomance-rating/shift-leads-rating"],
    }),
    getTasksMountedReport: builder.query<string, { unit: number }>({
      query(queryProps: { unit: number }) {
        return {
          url: `tasks/mounted-report/?${qs.stringify(queryProps)}`,
          responseHandler: async (response) => {
            if (response.ok) {
              const fileLink = document.createElement("a");
              fileLink.href = window.URL.createObjectURL(await response.blob());
              fileLink.target = "blank";
              const disposition = await response.headers.get("content-disposition");
              const fileName = getFileNameFromContentDisposition(disposition);
              fileLink.download = fileName ?? "report";
              fileLink.click();
            }
          },
        };
      },
    }),
    getTeamsShiftTasksReport: builder.query<string, ITeamsShiftTasksReportQueryProps>({
      query(queryProps: ITeamsShiftTasksReportQueryProps) {
        return {
          url: `teams/shift_tasks_report/?${qs.stringify(queryProps)}`,
          responseHandler: async (response) => {
            if (response.ok) {
              const fileLink = document.createElement("a");
              fileLink.href = window.URL.createObjectURL(await response.blob());
              fileLink.target = "blank";
              const disposition = await response.headers.get("content-disposition");
              const fileName = getFileNameFromContentDisposition(disposition);
              fileLink.download = fileName ?? "report";
              fileLink.click();
            }
          },
        };
      },
    }),
    getTeamsToCopy: builder.query<ITeamCopyModalState["teamsToCopy"], string>({
      query: (queryString) => `/teams/copy/?${queryString}`,
      providesTags: ["teams/teams-to-copy"],
    }),
    getUiPermissions: builder.query<IUiPermission[], string>({
      query: () => `/ui-elements/ui-elements-permissions/`,
      providesTags: ["ui-elements/ui-elements-permissions"],
      keepUnusedDataFor: 60,
    }),
    updateUiPermissions: builder.mutation<IUiPermission[], IUiPermissionBodyPayload[]>({
      query(payload: IUiPermissionBodyPayload[]) {
        return {
          url: "/ui-elements/is-user-allowed-to-use-this-ui-elements/",
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["ui-elements/ui-elements-permissions"],
    }),
    uploadTaskSignature: builder.mutation<any, ICreateTaskSignaturePayload>({
      query({ taskId, body, side }) {
        return {
          url: `/tasks/${taskId}/task-signature/${side}/`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["task-signature", "task-signature/file"],
    }),
    getTaskSignature: builder.query<ITaskSignature, number>({
      query: (taskId) => `tasks/${taskId}/task-signature/`,
      providesTags: ["task-signature"],
    }),
    getCustomerRepresentatives: builder.query<ICustomerRepresentative[], string>({
      query: (queryString) => `customer-representatives/?${queryString}`,
      providesTags: ["customer-representatives"],
      transformResponse: extractResults,
    }),
    getPositions: builder.query<IPosition[], string>({
      query: (queryString) => `positions/?${queryString}`,
      providesTags: ["positions"],
    }),
    createCustomerRepresentative: builder.mutation<ICustomerRepresentative, ICustomerRepresentativePayload>({
      query(payload) {
        return {
          url: `customer-representatives/`,
          method: "POST",
          body: {
            unit: payload.unit,
            first_name: payload.firstName,
            last_name: payload.lastName,
            middle_name: payload.middleName,
            position: payload.position,
          },
        };
      },
      invalidatesTags: ["customer-representatives"],
    }),
    getTaskSignatureFile: builder.query<string, IGetTaskSignatureFileProps>({
      query(props) {
        return {
          url: `/tasks/${props.taskId}/task-signature/${props.side}/file/`,
          method: "GET",
          responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        };
      },
      providesTags: ["task-signature/file"],
    }),
    createCustomerPosition: builder.mutation<IPosition, Omit<IPosition, "id">>({
      query(payload) {
        return {
          url: `positions/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["positions"],
    }),
    getTaskAttachmentsDownload: builder.query<string, number>({
      query(taskId) {
        return {
          url: `tasks/${taskId}/attachments/download/`,
          responseHandler: async (response) => {
            if (response.ok) {
              const fileLink = document.createElement("a");
              fileLink.href = window.URL.createObjectURL(await response.blob());
              fileLink.target = "blank";
              const disposition = await response.headers.get("content-disposition");
              const fileName = getFileNameFromContentDisposition(disposition);
              fileLink.download = fileName ?? `tasks_${taskId}_attachments`;
              fileLink.click();
            }
          },
        };
      },
    }),
    createUnitPreferences: builder.mutation<IUnitPreferences, Omit<IUnitPreferences, "id">>({
      query(payload) {
        return {
          url: `unit-preferences/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["unit-preferences"],
    }),
    deleteUnitPreferences: builder.mutation<IUnitPreferences, number>({
      query(id) {
        return {
          url: `unit-preferences/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["unit-preferences"],
    }),
    updateUnitPreferences: builder.mutation<IUnitPreferences, IUnitPreferences>({
      query(unitPreferences) {
        return {
          url: `unit-preferences/${unitPreferences.id}/`,
          method: "PUT",
          body: unitPreferences,
        };
      },
      invalidatesTags: ["unit-preferences"],
    }),
    getUnitPreferences: builder.query<IUnitPreferences[], Omit<IUnitPreferences, "id" | "name" | "preferences">>({
      query({ unit, key }) {
        return {
          url: `unit-preferences/?unit=${unit}&key=${key}`,
        };
      },
      providesTags: ["unit-preferences"],
    }),
    getRecoveryTasksNotifications: builder.query<IResponseBody<IRecoveryTasksNotification>, string>({
      query() {
        return {
          url: `notifications/tasks/`,
        };
      },
      providesTags: ["notifications/tasks"],
      keepUnusedDataFor: 5,
    }),
    skipRecoveryTasksNotification: builder.mutation<
      ISkipRecoveryTasksNotificationBody,
      ISkipRecoveryTasksNotificationBody
    >({
      query(body) {
        return {
          url: `/notifications/tasks/skip/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["notifications/tasks"],
    }),
    planTaskNotification: builder.mutation<IPlanTasksNotificationBody, IPlanTasksNotificationBody>({
      query(body) {
        return {
          url: `/notifications/tasks/plan/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["notifications/tasks"],
    }),
    getUserSettingsTasksNotifications: builder.query<IUserSettingsTasksNotifications, string>({
      query() {
        return {
          url: `user-settings/task-notifications/`,
        };
      },
      providesTags: ["user-settings/task-notifications"],
    }),
    getItsmCategoryGroups: builder.query<IItsmCategoryGroup[], string>({
      query: (queryString) => `/itsm-category-group/?${queryString}`,
      providesTags: ["itsm-category-groups"],
      transformResponse: extractResults,
    }),
    getItsmCategories: builder.query<IItsmCategory[], string>({
      query: (queryString) => `/itsm-request-category/?${queryString}`,
      providesTags: ["itsm-request-categories"],
      transformResponse: extractResults,
    }),
    getItsmBaseEquipmentType: builder.query<IDictionaryItem[], string>({
      query: (queryString) => `/itsm-equipment/?${queryString}`,
      providesTags: ["itsm-equipment"],
      transformResponse: extractResults,
    }),
    sendCreatioTask: builder.mutation<void, ISendCreatioTaskBody>({
      query(body) {
        return {
          url: `/ksip-adapter/new-task-requests/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        "tasks",
        "shifttask",
        "tasks-filtered",
        "kanban-board",
        "month-plan",
        "tasks/statistics-by-days",
        "dashboard/shift-statistic/tasks",
        "dashboard/shift-statistic/tasks/by-equipment-types",
        "dashboard/shift-statistic/tasks/by-objects/slpi",
      ],
    }),
    getWorkTypes: builder.query<TWorkType[], ApiSliceEnum | null>({
      query: (arg) => {
        switch (arg) {
          case ApiSliceEnum.lso:
            return "/lso-work-types";
          case ApiSliceEnum.lku:
            return "/lku-work-types";
          case ApiSliceEnum.vols:
            return "/vols-work-types";
          case ApiSliceEnum.slpi:
            return "/slpi-work-types";
          case ApiSliceEnum.pspa:
          case ApiSliceEnum.pspaKf:
            return "/work-types";
          case ApiSliceEnum.oossa:
            return "/oossa-work-types";

          default:
            throw new Error("Wrong api slice");
        }
      },
      providesTags: ["work-types"],
      transformResponse: (response: IResponseBody<TWorkType>, meta, arg) => response.results,
    }),
    getWhoami: builder.query<IWhoami, string>({
      query: () => `/auth/whoami/`,
      providesTags: ["auth/whoami"],
    }),
    importSlpiMonthPlans: builder.mutation({
      query: (payload) => ({
        url: `slpi-month-plan/load_month_plans/`,
        method: "POST",
        body: payload,
        cache: "no-cache",
      }),
      invalidatesTags: ["slpi-object", "slpi-object/slpi_stat"],
    }),
    getWorkRegimes: builder.query<IWorkRegime[], number>({
      query: (unitId: number) => `units/${unitId}/work-regimes/`,
      providesTags: ["units/work-regimes"],
      transformResponse: extractShifts,
    }),
    createMonthPlans: builder.mutation<ICreateMonthPlansResponse, ICreateMonthPlansPayload>({
      query(payload) {
        return {
          url: `month-plan/create-plans/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["month-plan", "year-plan/transports-stats"],
    }),
    createPspaKFPlans: builder.mutation<void, ICreatePspaKFPlansPayload>({
      query(payload) {
        return {
          url: `pspa-kf-year-plans/create-plans/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat"],
    }),
    getItsmServiceOfferinge: builder.query<IItsmServiceOfferinge[], string>({
      query: () => `/itsm-service-offerings/`,
      providesTags: ["itsm-service-offerings"],
      transformResponse: extractResults,
    }),
    getDailySummaryReport: builder.query<IDailySummaryReport, string>({
      query: (queryString) => `/report/daily-summary?${queryString}`,
      providesTags: ["report/daily-summary"],
    }),
    getDailySummaryReportExist: builder.query<IDailySummaryReportExist, string>({
      query: (queryString) => `/report/daily-summary-is-exist?${queryString}`,
      providesTags: ["report/daily-summary-is-exist"],
    }),
    updateDailySummaryReport: builder.mutation<IDailySummaryReport, IUpdateDailySummaryReportBody>({
      query(payload) {
        return {
          url: `report/daily-summary`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["report/daily-summary"],
    }),
    getDashboardSlpiMaintenancePlanFactStatistic: builder.query<IDashboardSlpiMaintenancePlanFactStatistic, string>({
      query: (queryString) => `/dashboard/slpi-maintenance-plan-fact-statistic/?${queryString}`,
      providesTags: ["dashboard/slpi-maintenance-plan-fact-statistic"],
    }),
    updateOosaTask: builder.mutation({
      query: ({ rtkQueryCacheKey, ...task }) => ({
        url: `oossa-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted(args, queryContext) {
        await handleUpdateTaskQueryStarted(args, queryContext);
      },
      invalidatesTags: (result, error, arg) => [{ type: "task", id: arg.id }, ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE],
    }),
    getOossaObjects: builder.query<IResponseBody<IDictionaryItem>, string>({
      query: (queryString) => `/oossa-objects/?${queryString}`,
      providesTags: ["oossa/objects"],
    }),
    getOossaObject: builder.query<IOossaObject, number>({
      query: (id) => `/oossa-objects/${id}/`,
      providesTags: ["oossa/object"],
    }),
    getOossaEquipments: builder.query<IResponseBody<IOossaEquipment>, string>({
      query: (queryString) => `/oossa-equipments/?${queryString}`,
      providesTags: ["oossa/equipments"],
    }),
    getOossaEquipment: builder.query<IOossaEquipment, number>({
      query: (id) => `/oossa-equipments/${id}/`,
      providesTags: ["oossa/equipment"],
    }),
    getOossaSystems: builder.query<IResponseBody<IDictionaryItem>, string>({
      query: (queryString) => `/oossa-systems/?${queryString}`,
      providesTags: ["oossa/systems"],
    }),
    getOossaYearPlans: builder.query<IOossaYearPlan[], IOossaYearPlansQueryPayload>({
      query(payload) {
        const queryString = qs.stringify(payload);
        return `/oossa-year-plans/?${queryString}`;
      },
      transformResponse: extractResults,
      providesTags: ["oossa-year-plans"],
    }),
    getOossaYearPlansStat: builder.query<IOossaYearPlansStat, IOossaYearPlansQueryPayload>({
      query(payload) {
        const queryString = qs.stringify(payload);
        return `/oossa-year-plans/oossa-stat/?${queryString}`;
      },
      providesTags: ["oossa-year-plans/oossa_stat"],
    }),
    updateOossaYearPlan: builder.mutation<IPlanByMonth, Pick<IPlanByMonth, "id" | "maintenance_quantity">>({
      query(payload) {
        return {
          url: `/oossa-year-plan-by-months/${payload.id}/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["oossa-year-plans"],
    }),
    approveOossaMonthPlan: builder.mutation<IApproveOossaMonthPlan, IApproveOossaMonthPlanPayload>({
      query(payload) {
        return {
          url: `oossa-month-plans/approve/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["oossa-year-plans", "oossa-year-plan-by-months/month-plan-approval-state", "oossa-month-plans"],
    }),
    undoApproveOossaMonthPlan: builder.mutation<string, IApproveOossaMonthPlanPayload>({
      query(payload) {
        return {
          url: `oossa-month-plans/undo-approve/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["oossa-year-plans", "oossa-year-plan-by-months/month-plan-approval-state", "oossa-month-plans"],
    }),
    updateOossaMonthPlan: builder.mutation<
      IOossaMonthPlan,
      { plan: IOossaMonthPlan; queryProps: IOossaMonthPlanQueryPayload }
    >({
      query(payload) {
        return {
          url: `oossa-month-plans/${payload.plan.id}/`,
          method: "PUT",
          body: payload.plan,
        };
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getOossaMonthPlan", payload.queryProps, (cachedPlans) => {
            const cachedPlanToUpdate = cachedPlans.find((cachedPlan) => cachedPlan.id === payload.plan.id);
            if (cachedPlanToUpdate) {
              Object.assign(cachedPlanToUpdate, payload.plan);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch (err: any) {
          const errorText = get(err, "error.data.errors", "Произошла ошибка, попробуйте позже");
          message.error(errorText);
          patchResult.undo();
        }
      },
      invalidatesTags: ["oossa-month-plans"],
    }),
    createOossaMonthPlan: builder.mutation<any, IOossaMonthPlansCreateQueryPayload>({
      query(payload) {
        return {
          url: `oossa-month-plans/make-schedule/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["oossa-month-plans"],
    }),
    getOossaMonthPlan: builder.query<IOossaMonthPlan[], IOossaMonthPlanQueryPayload>({
      query(payload) {
        const queryString = qs.stringify({
          limit: 1000,
          ...payload,
        });
        return `/oossa-month-plans/?${queryString}`;
      },
      providesTags: ["oossa-month-plans"],
      transformResponse: extractResults,
    }),
    extendPreviousMonth: builder.mutation<any, IExtendPreviousMonthQueryPayload>({
      query(payload) {
        return {
          url: `month-schedule/${payload.id}/extend-previous-month/`,
          method: "PUT",
          body: payload.prevMonth,
        };
      },
      invalidatesTags: ["month-schedule"],
    }),
    getSlpiWorkTypes: builder.query<ISlpiWorkType[], string>({
      query() {
        return `slpi-work-types/`;
      },
      providesTags: ["slpi-work-types"],
      transformResponse: extractResults,
    }),
    getSlpiUnits: builder.query<IDictionaryItem[], string>({
      query(code) {
        return `units/service-units/${code}`;
      },
      providesTags: ["slpi-units"],
    }),
    getNoPhotoReasons: builder.query<INoPhotoReason[], string>({
      query() {
        return `no-photo-reasons/`;
      },
      providesTags: ["no-photo-reasons"],
      transformResponse: extractResults,
    }),
    getCustomerDepartments: builder.query<ICustomerDepartment[], string>({
      query: (queryString) => `customer-departments/?${queryString}`,
      providesTags: ["customer-departments"],
      transformResponse: extractResults,
    }),
    getCustomerDepartment: builder.query<ICustomerDepartment, string | number>({
      query: (id) => `customer-departments/${id}/`,
      providesTags: ["customer-department"],
    }),
    getUnitDepartments: builder.query<IUnitDepartment[], string>({
      query: (queryString) => `unit-departments/?${queryString}`,
      providesTags: ["unit-departments"],
    }),
    getApproveMonthPlanHistory: builder.query<IApproveMonthPlanHistory[], IApproveMonthPlanHistoryPayload>({
      query: (queryProps) => `approve-month-plan/?${qs.stringify(queryProps)}`,
      providesTags: ["approve-month-plan"],
      transformResponse: extractResults,
    }),
    getPspaMonthPlanSignature: builder.query<IPspaMonthPlanSignature[], IGetPspaMonthPlanSignatureProps>({
      query: (queryProps) => `pspa-month-plan-signature/?${qs.stringify(queryProps)}`,
      providesTags: ["pspa-month-plan-signature"],
    }),
    updatePspaMonthPlanSignature: builder.mutation<IPspaMonthPlanSignature[], IUpdatePspaMonthPlanSignatureProps>({
      query: ({ urlPart, body, id }) => ({
        url: `pspa-month-plan-signature/${id}/${urlPart}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["pspa-month-plan-signature"],
    }),
    createSlpiMonthPlans: builder.mutation<null, ICreateSlpiMonthPlansPayload>({
      query: (body) => ({
        url: `slpi-object/create-month-plans/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["slpi-object/slpi_stat", "slpi-object"],
    }),
    getManufacturers: builder.query<IDictionaryItem[], void>({
      query: () => `manufacturers/`,
      providesTags: ["manufacturers"],
      transformResponse: extractResults,
    }),
    getOossaTechNode: builder.query<IOossaTechNode, number>({
      query(id) {
        return `/oossa-tech-nodes/${id}/`;
      },
      providesTags: ["oossa-tech-node"],
    }),
    getOossaTechNodes: builder.query<IOossaTechNode[], string>({
      query(queryString) {
        return `/oossa-tech-nodes/?${queryString}`;
      },
      providesTags: ["oossa-tech-nodes"],
      transformResponse: extractResults,
    }),
    getOossaMonthPlansApprovalState: builder.query<TOossaMonthPlanState[], string>({
      query: (queryString) => `/oossa-year-plan-by-months/month-plan-approval-state/?${queryString}`,
      providesTags: ["oossa-year-plan-by-months/month-plan-approval-state"],
    }),
    getOossaMaintenancePlanFactStatistic: builder.query<IOossaMaintenancePlanFactStatistic, string>({
      query: (queryString) => `/dashboard/oossa-maintenance-plan-fact-statistic/?${queryString}`,
      providesTags: ["dashboard/oossa-maintenance-plan-fact-statistic"],
    }),
    getOossaWorkTypes: builder.query<IOossaWorkType[], string>({
      query: (queryString) => `/oossa-work-types/?${queryString}`,
      providesTags: ["oossa-work-types"],
      transformResponse: extractResults,
    }),
    getSlpiMonthPlansAndEffort: builder.query<ISlpiMonthPlansAndEffort, string>({
      query: (queryString) => `slpi-month-plan/hours-norm/?${queryString}`,
      providesTags: ["slpi-month-plan/hours-norm"],
    }),
    getMatomoEnvironment: builder.query<string, string>({
      query: () => `matomo-environment/`,
      providesTags: ["matomo-environment"],
    }),
    getScheduleStatsEmployees: builder.query<IScheduleStatsEmployees, string>({
      query: (queryString) => `schedule-stats-employees/?${queryString}`,
      providesTags: ["schedule-stats-employees"],
    }),
    getEtoMonthPlansStat: builder.query<IEtoMonthPlanStat[], string>({
      query: (queryString) => `eto-month-plans/eto-statistic/?${queryString}`,
      providesTags: ["eto-month-plans/eto-statistic"],
    }),
    updateEtoStep: builder.mutation<null, IUpdateEtoStepPayload>({
      query: ({ step }) => ({
        url: `eto-steps/${step.id}/`,
        method: "PATCH",
        body: {
          ...step,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: "task", id: arg.taskId }],
    }),
    createEtoMonthPlans: builder.mutation<null, ICreateEtoMonthPlansPayload>({
      query: (payload) => ({
        url: `eto-month-plans/create-plans/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["eto-month-plans/eto-statistic", "eto-month-plans"],
    }),
    approveEtoMonthPlans: builder.mutation<null, ICreateEtoMonthPlansPayload>({
      query: (payload) => ({
        url: `eto-month-plans/approve/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["eto-month-plans/eto-statistic", "eto-month-plans"],
    }),
    undoApproveEtoMonthPlans: builder.mutation<null, ICreateEtoMonthPlansPayload>({
      query: (payload) => ({
        url: `eto-month-plans/undo-approve/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["eto-month-plans/eto-statistic", "eto-month-plans"],
    }),
    updateEtoMonthPlan: builder.mutation<null, IUpdateEtoMonthPlanPayload>({
      query: (payload) => {
        const { id, ...rest } = payload;
        return {
          url: `eto-month-plans/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: ["eto-month-plans"],
    }),
    getEtoMonthPlans: builder.query<IEtoMonthPlan[], string>({
      query: (queryString) => `eto-month-plans/?${queryString}`,
      providesTags: ["eto-month-plans"],
      transformResponse: extractResults,
    }),
    makeScheduleEtoMonthPlans: builder.mutation<null, ICreateEtoMonthPlansPayload>({
      query: (payload) => ({
        url: `eto-month-plans/make-schedule/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["eto-month-plans"],
    }),
    createEtoStepDriverSignature: builder.mutation<{ id: number }, ICreateEtoStepSignaturePayload>({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `eto-steps/${id}/driver-signature/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, payload) => [{ type: "task", id: payload.taskId }],
    }),
    createEtoStepMechanicSignature: builder.mutation<{ id: number }, ICreateEtoStepSignaturePayload>({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `eto-steps/${id}/mechanic-signature/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, payload) => [{ type: "task", id: payload.taskId }],
    }),
    getEmployeeSignature: builder.query<IEmployeeSignature, number>({
      query: (id) => ({
        url: `employee-signatures/${id}/`,
        responseHandler: async (response) => {
          const data: IEmployeeSignature = await response.json();

          if (data.signature) {
            const signatureUrl = new URL(data.signature);
            data.signature = `${baseUrl}minio/${data.signature.slice(signatureUrl.origin.length + 1)}`;
          }

          return data;
        },
      }),
    }),
    getMaintenanceTypes: builder.query<IMaintenanceType[], string>({
      query: (queryString) => `maintenance-types/?${queryString}`,
      providesTags: ["maintenance-types"],
      transformResponse: extractResults,
    }),
  }),
});

export const {
  useCreateSlpiMonthPlansMutation,
  useGetEmployeesQuery,
  useGetTeamsQuery,
  useCreateTeamMutation,
  useCreateTeamsMutation,
  useDeleteTeamMutation,
  useDisbandTeamMutation,
  useUpdateTeamMutation,
  useGetUnitsQuery,
  useGetTasksQuery,
  useUpdatePspaTaskMutation,
  useUpdateSlpiTaskMutation,
  useGetShiftTasksQuery,
  useGetYearPlanQuery,
  useGetEquipmentTypesQuery,
  useGetMonthPlanQuery,
  useGetTasksFilteredQuery,
  useUpdateMonthPlanMutation,
  useUpdateYearPlanMutation,
  useCreateMonthPlanTasksMutation,
  useGetTransportStatMutation,
  useMonthPlanFindOrCreateTaskMutation,
  useGetTaskQuery,
  useLazyGetTaskQuery,
  useUpdateTaskCheckListMutation,
  useMonthPlanUndoApproveMutation,
  useGetTaskStatesQuery,
  useUpdateTaskStateMutation,
  useMakeMonthPlanScheduleMutation,
  useCopyMonthPlanMutation,
  useGetMonthPlanInitialPeriodQuery,
  useGetTransportQuery,
  useGetTransportMaintenanceQuery,
  useGetScheduledTaskCountQuery,
  useGetPspaKFMonthPlansScheduledTaskCountQuery,
  useResetTaskQuery,
  useGetRequiredTaskCountQuery,
  useGetKanbanBoardQuery,
  useGetLocationsQuery,
  useGetLocationQuery,
  useGetRepairAreasQuery,
  useGetSystemsQuery,
  useGetSystemQuery,
  useGetEquipmentsQuery,
  useGetHorizonsQuery,
  useCreateTokenMutation,
  useGetActByPeriodMutation,
  useGetActMutation,
  useImportTasksMutation,
  useGetMonthPlanPrintMutation,
  useGetMonthScheduleQuery,
  useGetCreatioStatesQuery,
  useGetOpenTasksQuery,
  useCreateTaskMutation,
  useGetGroupTasksQuery,
  useGetTasksStatisticsByDaysQuery,
  useGetTasksStatisticsByShiftsQuery,
  useGetTasksStatisticsByTeamsQuery,
  useCreateMonthScheduleMutation,
  useGetUnitShiftsQuery,
  useGetWorkingRhythmQuery,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGetMonthPlanPermissionQuery,
  useGetMontScheduleAvailableEmployeesQuery,
  useUpdateGroupMutation,
  useGetMontScheduleAvailableLocationsQuery,
  useCreateRecoveryTasksMutation,
  useNeedCreateRecoveryTasksQuery,
  useGetDashboardOpenedTasksStatisticQuery,
  useGetDashboardMaintenancePlanFactStatisticQuery,
  useUploadAttachmentMutation,
  useDeleteAttachmentMutation,
  useSetAttachmentShouldBeSentMutation,
  useGetMediaQuery,
  useGetTeamQuery,
  useCreateGroupScheduledDayMutation,
  useDeleteGroupScheduledDayMutation,
  useCreateEmployeeScheduledDayMutation,
  useDeleteEmployeeScheduledDayMutation,
  useGetTaskAttachmentsQuery,
  useGetItsmClosingCodesQuery,
  useGetItsmDelayReasonsQuery,
  useGetItsmEquipmentTypesQuery,
  useGetItsmEquipmentQuery,
  useGetItsmEquipmentMalfunctionQuery,
  useGetItsmEquipmentMalfunctionReasonQuery,
  useGetAddressesQuery,
  useGetAddressQuery,
  useGetYearPlanTransportsStatsQuery,
  useMakeStationaryEquipmentScheduleMutation,
  useGetPermissionsQuery,
  useGetSlpiObjectsMonthPlansQuery,
  useUpdateSlpiMonthPlanMutation,
  useGetShiftPlanQuery,
  useChangeShiftPlanStatusMutation,
  useApproveSlpiMonthPlanMutation,
  useGetCustomersQuery,
  useUndoApproveSlpiMonthPlanMutation,
  useGetSlpiObjectStatQuery,
  useGetResponsibleGroupsQuery,
  useGetUnitQuery,
  useGetEquipmentsTreeQuery,
  useGetEquipmentQuery,
  useGetEquipmentLastProblemQuery,
  useGetEquipmentsLastProblemsQuery,
  useGetEquipmentMaintenanceQuery,
  useGetEquipmentMaintenanceJournalQuery,
  useGetEquipmentCommentJournalQuery,
  useGetEquipmentTypeQuery,
  useGetDashboardShiftStatisticQuery,
  useGetDashboardShiftStatisticTasksQuery,
  useGetDashboardShiftStatisticTasksByEquipmentTypesQuery,
  useGetShiftPlanPermissionsQuery,
  useGetDashboardShiftStatisticTasksByTeamsSlpiQuery,
  useGetDashboardShiftStatisticTasksByTeamsPspaQuery,
  useImportSLPITasksMutation,
  useGetSlpiSystemComplexQuery,
  useGetSlpiDirectObjectsQuery,
  useGetServicesQuery,
  useGetVideoHelpQuery,
  useGetVideoHelpFileMutation,
  useCreateVacationOrSickLeaveDaysMutation,
  useCreateMaintenanceMountedTaskMutation,
  useGetPrintTasksFormQuery,
  useLazyGetPrintTasksFormQuery,
  useGetPerfomanceRatingAverageShiftTasksQuantityQuery,
  useGetPerfomanceRatingHallOfFameQuery,
  useGetPerfomanceRatingTreeQuery,
  useGetPerfomanceRatingTasksByShiftQuery,
  useGetPerfomanceRatingTeamLeadsRatingQuery,
  useGetPerfomanceRatingShiftLeadsRatingQuery,
  useLazyGetTasksMountedReportQuery,
  useGetDashboardShiftStatisticTasksByObjectsSlpiQuery,
  useLazyGetTeamsShiftTasksReportQuery,
  useUploadTaskSignatureMutation,
  useGetTaskSignatureQuery,
  useGetCustomerRepresentativesQuery,
  useCreateCustomerRepresentativeMutation,
  useGetPositionsQuery,
  useGetTaskSignatureFileQuery,
  useCreateCustomerPositionMutation,
  useLazyGetTaskAttachmentsDownloadQuery,
  useCreateUnitPreferencesMutation,
  useGetUnitPreferencesQuery,
  useDeleteUnitPreferencesMutation,
  useUpdateUnitPreferencesMutation,
  useGetRecoveryTasksNotificationsQuery,
  useSkipRecoveryTasksNotificationMutation,
  usePlanTaskNotificationMutation,
  useGetUserSettingsTasksNotificationsQuery,
  useGetItsmCategoryGroupsQuery,
  useGetItsmCategoriesQuery,
  useGetItsmBaseEquipmentTypeQuery,
  useSendCreatioTaskMutation,
  useGetWorkTypesQuery,
  useGetWhoamiQuery,
  useImportSlpiMonthPlansMutation,
  useGetWorkRegimesQuery,
  useCreateMonthPlansMutation,
  useCreatePspaKFPlansMutation,
  useGetItsmServiceOfferingeQuery,
  useGetDailySummaryReportQuery,
  useUpdateDailySummaryReportMutation,
  useLazyCheckSlpiMonthPlanQuery,
  useGetSlpiDirectObjectsLightQuery,
  useGetDailySummaryReportExistQuery,
  useLazyGetDailySummaryReportQuery,
  useGetDashboardSlpiMaintenancePlanFactStatisticQuery,
  useGetSlpiSystemComplexLightQuery,
  useCreateSlpiTaskMutation,
  useGetAvailableShiftPlanStatusesQuery,
  useUpdateOosaTaskMutation,
  useGetOossaObjectsQuery,
  useGetOossaObjectQuery,
  useGetOossaSystemsQuery,
  useGetOossaEquipmentsQuery,
  useGetOossaYearPlansQuery,
  useGetOossaMonthPlanQuery,
  useUpdateOossaYearPlanMutation,
  useUpdateOossaMonthPlanMutation,
  useCreateOossaMonthPlanMutation,
  useGetUiPermissionsQuery,
  useUpdateUiPermissionsMutation,
  useApproveOossaMonthPlanMutation,
  useUndoApproveOossaMonthPlanMutation,
  useExtendPreviousMonthMutation,
  useGetSlpiWorkTypesQuery,
  useGetSlpiUnitsQuery,
  useGetNoPhotoReasonsQuery,
  useGetCustomerDepartmentsQuery,
  useGetUnitDepartmentsQuery,
  useGetCustomerDepartmentQuery,
  useGetCheckMonthPlanQuery,
  useGetApproveMonthPlanHistoryQuery,
  useGetPspaMonthPlanSignatureQuery,
  useUpdatePspaMonthPlanSignatureMutation,
  useGetOossaYearPlansStatQuery,
  useGetManufacturersQuery,
  useGetOossaTechNodeQuery,
  useGetOossaMonthPlansApprovalStateQuery,
  useGetOossaTechNodesQuery,
  useGetOossaEquipmentQuery,
  useGetOossaMaintenancePlanFactStatisticQuery,
  useGetPerformanceRatingUsefulTimeFactorQuery,
  useGetOossaWorkTypesQuery,
  useCreateOossaTaskMutation,
  useGetDashboardShiftStatisticTasksByTeamsOossaQuery,
  useGetDashboardShiftStatisticTasksByObjectsOossaQuery,
  useGetSlpiMonthPlansAndEffortQuery,
  useGetMatomoEnvironmentQuery,
  useGetScheduleStatsEmployeesQuery,
  useUpdateEtoTaskMutation,
  useGetEtoTaskQuery,
  useUpdateEtoStepMutation,
  useGetEtoMonthPlansStatQuery,
  useCreateEtoMonthPlansMutation,
  useGetEtoMonthPlansQuery,
  useApproveEtoMonthPlansMutation,
  useUndoApproveEtoMonthPlansMutation,
  useUpdateEtoMonthPlanMutation,
  useMakeScheduleEtoMonthPlansMutation,
  useCreateEtoStepDriverSignatureMutation,
  useCreateEtoStepMechanicSignatureMutation,
  useGetTeamsToCopyQuery,
  useGetEmployeeSignatureQuery,
  useGetEtoTasksQuery,
  useGetOossaTasksQuery,
  useGetPspaTasksQuery,
  useGetSlpiTasksQuery,
  useGetPspaWithEtoTasksQuery,
  useGetMaintenanceTypesQuery,
  util,
} = apiSlice;
