import { Form, Drawer } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { Category } from "core/widgets/TaskModals/shared/Media/Category";
import { TaskAttachmentCategoryEnum } from "core/app/api/interfaces";
import { MediaGallery } from "core/widgets/AssistanceCarTaskModal/AssistanceCarMaintenance/Body/Media/MediaGallery";
import { MediaContext, useMediaContextInitialValue } from "core/shared/task/context/mediaContext";
import { useGetTaskAttachmentsQuery } from "core/app/api";

export function FormMedia() {
  const taskId = Form.useWatch("id");
  const contextValue = useMediaContextInitialValue();
  const { setSelectedMediaId, selectedMediaId } = contextValue;
  const { data } = useGetTaskAttachmentsQuery(taskId ?? skipToken);

  return (
    <MediaContext.Provider value={contextValue}>
      <Category task={{ id: taskId }} isDisabled={false} category={TaskAttachmentCategoryEnum.FilesByTask} />
      <Drawer
        open={!!selectedMediaId}
        onClose={() => {
          setSelectedMediaId(null);
        }}
        width="100VW"
        zIndex={2000}
      >
        {data && <MediaGallery attachments={data} />}
      </Drawer>
    </MediaContext.Provider>
  );
}
