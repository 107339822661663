import { ReactElement } from "react";
import { Select, Form } from "antd";
import type { SelectProps } from "antd";
import qs from "qs";

import { IDictionaryItem } from "core/shared/interfaces";
import { useGetTransportQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";

const LIMIT = 5000;

interface TransportFieldProps {
  required?: true;
  selectProps?: SelectProps;
}

function TransportField({ required, selectProps }: TransportFieldProps): ReactElement {
  const [unitId] = useUnitId();
  const form = Form.useFormInstance();

  const queryParams: Record<string, string | number> = {
    active_for: dayjs().format(apiDateFormat),
    limit: LIMIT,
  };
  if (unitId) {
    queryParams.unit = unitId;
  }

  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetTransportQuery(queryString);

  const options = (data?.results || []).map((item: IDictionaryItem) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = (val: number) => {
    const transport = (data?.results || []).find((item) => item.id === val);
    if (transport) {
      form.setFieldValue("location_id", transport.location_id);
    } else {
      form.setFieldValue("location_id", null);
    }
    form.setFieldValue("equipment_type_id", null);
  };

  return (
    <Form.Item
      label="ТРАНСПОРТ"
      name="transport_id"
      colon={false}
      rules={required && [{ required: true, message: "" }]}
    >
      <Select
        loading={isFetching}
        allowClear
        disabled={isFetching}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleChange}
        {...selectProps}
      />
    </Form.Item>
  );
}

export default TransportField;
