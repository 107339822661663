export enum VolsEquipmentTypeCodeEnum {
  LINE = "LINE",
  CROSS = "CROSS",
}
/** @todo на беке нужно схлопнуть VolsEquipmentTypeCodeEnum и VolsEquipmentTypeNameEnum */
export enum VolsEquipmentTypeNameEnum {
  LINE = "line",
  CROSS = "cross",
}

export enum VolsEntityTypeEnum {
  CROSS = "CROSS",
  LINE = "LINE",
}
