import { ReactElement } from "react";
import { Row, Col } from "antd";

import { isGroup, IGroup } from "core/shared/dashboard";
import { IDashboardShiftStatisticTasksByTeamsVolsTask } from "core/app/api/VOLS/interfaces";

type TTableRecord = IDashboardShiftStatisticTasksByTeamsVolsTask | IGroup<IDashboardShiftStatisticTasksByTeamsVolsTask>;

interface CellProps {
  record: TTableRecord;
}

export function VolsObject({ record }: CellProps): ReactElement {
  if (isGroup(record)) {
    return <div />;
  }

  return (
    <Row>
      <Col>{record.object_name || "НЕ УКАЗАНО"}</Col>
    </Row>
  );
}
