import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function Deadline({ task }: FieldProps): ReactElement {
  const value = `${dayjs(task.deadline).format(displayDateFormat)} г.`;
  return <Field label="Плановая дата выполнения" value={value} />;
}

export { Deadline };
