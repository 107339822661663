import { useState } from "react";
import { Typography } from "antd";
import type { ParagraphProps } from "antd/lib/typography/Paragraph";

const { Paragraph: AntParagraph } = Typography;

export function Paragraph({ children, ...rest }: ParagraphProps) {
  const [isEllipsis, setEllipsis] = useState(true);
  return (
    <AntParagraph
      onClick={() => {
        setEllipsis(!isEllipsis);
      }}
      ellipsis={isEllipsis ? { rows: 4 } : false}
      style={{ marginBottom: 0, cursor: "pointer" }}
      {...rest}
    >
      {children}
    </AntParagraph>
  );
}
