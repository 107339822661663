import { Row, Col, Form } from "antd";
import { NavLink } from "react-router-dom";

import { useUnit } from "core/shared/unit/useUnit";
import { AppRoutes } from "core/shared/constants.routes";
import Text from "core/lib/Text/Text";
import { useAppDispatch } from "core/app/store";
import { setSelectedTab } from "core/app/store/maintenance-slice";

import { TableLso } from "../Table/TableLso";
import { DashboardMaintenanceFilters } from "../DashboardMaintenanceFilters";

import { BarsLso } from "./Bars";

export default function LsoMaintenanceStatistic() {
  const unit = useUnit();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  if (!unit?.isLSO()) {
    return null;
  }

  const handleClick = () => {
    dispatch(setSelectedTab("1"));
  };

  return (
    <Form form={form}>
      <Row gutter={60}>
        <Col span={6}>
          <Row>
            <Col span={24}>
              <div className="mb-2">
                <NavLink to={AppRoutes.pssLso} onClick={handleClick}>
                  <Text className="uppercase navlink" boldness="semibold">
                    Статистика по ТО
                  </Text>
                </NavLink>
              </div>
            </Col>
            <Col span={24}>
              <DashboardMaintenanceFilters />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <TableLso />
        </Col>
        <Col span={10} style={{ height: 250 }}>
          <BarsLso />
        </Col>
      </Row>
    </Form>
  );
}
