/** Вернуть число, округленное до указанного количества знаков после запятой.
 *
 * @param value Число, которое нужно округлить.
 * @param qtyAfterComma Максимальное количество знаков после запятой, которое должно остаться после округления.
 * @returns Округленное число.
 */
export function roundTo(value: number, qtyAfterComma: number): number {
  if (value === 0) return 0;
  const n = 10 ** qtyAfterComma;
  return Math.round(value * n) / n;
}
