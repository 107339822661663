import { EtoTaskAttachmentCategoryEnum, IAttachment, TaskAttachmentCategoryEnum } from "core/app/api/interfaces";
import { getAttachmentsCategoryCount, mediaCategoryTitles } from "core/shared/task";

interface EmptyAttachmentWarningProps {
  attachments?: IAttachment[];
  categories?: TaskAttachmentCategoryEnum[] | EtoTaskAttachmentCategoryEnum[];
}

function EmptyAttachmentWarning({ attachments, categories }: EmptyAttachmentWarningProps) {
  const rows = [];
  if (!categories) {
    categories = Object.values(TaskAttachmentCategoryEnum);
  }

  for (let index = 0; index < categories.length; index += 1) {
    const category = categories[index];
    if (
      getAttachmentsCategoryCount(attachments, category) === 0 &&
      category !== TaskAttachmentCategoryEnum.FilesByTask
    ) {
      rows.push({
        label: `В разделе '${mediaCategoryTitles[category]}' не прикреплена ни одна фотография.`,
        key: category,
      });
    }
  }
  if (!rows.length) {
    return <div />;
  }

  return (
    <div>
      {rows.map((row) => (
        <div key={row.key}>{row.label}</div>
      ))}
      <div>Вы уверены, что хотите закрыть задачу?</div>
    </div>
  );
}

export { EmptyAttachmentWarning };
