import { Col, Form, InputNumber, Row, Select, SelectProps } from "antd";

import { IEtoTask, TransportStatusEnum } from "core/app/api/interfaces";
import Text from "core/lib/Text/Text";
import { useFieldsDisabled } from "core/widgets/AssistanceCarTaskModal/hooks/useDisabled";

const TransportStatusMap = new Map([
  [TransportStatusEnum.IN_WORK, "В работе"],
  [TransportStatusEnum.IN_WORK_WITH_MALFUNCTION, "В работе с замечаниями"],
  [TransportStatusEnum.NOT_IN_WORK, "Не в работе"],
]);

const options: SelectProps["options"] = [
  {
    label: TransportStatusMap.get(TransportStatusEnum.IN_WORK),
    value: TransportStatusEnum.IN_WORK,
  },
  {
    label: TransportStatusMap.get(TransportStatusEnum.IN_WORK_WITH_MALFUNCTION),
    value: TransportStatusEnum.IN_WORK_WITH_MALFUNCTION,
  },
  {
    label: TransportStatusMap.get(TransportStatusEnum.NOT_IN_WORK),
    value: TransportStatusEnum.NOT_IN_WORK,
  },
];

type TStateFieldsProps = {
  task: IEtoTask;
};

function StateFields({ task }: TStateFieldsProps) {
  const isDisabled = useFieldsDisabled();

  return (
    <>
      <Row gutter={[12, 16]}>
        <Col span={12}>
          <Form.Item
            name="transport_status_from_driver"
            label={
              <Text size="label4" transform="uppercase">
                Тех. состояние СДО
              </Text>
            }
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(new Error("Пожалуйста, введите значение"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            required
          >
            <Select disabled={isDisabled} className="w-100" size="large" options={options} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["transport", "engine_hours"]}
            label={
              <Text size="label4" transform="uppercase">
                Факт мото-часов
              </Text>
            }
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(new Error("Пожалуйста, введите значение"));
                  }
                  if (task.transport.engine_hours && value < task.transport.engine_hours) {
                    return Promise.reject(
                      new Error(`Новое значение не может быть меньше ${task.transport.engine_hours}`)
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            required
          >
            <InputNumber disabled={isDisabled} min={task.transport.engine_hours} className="w-100" size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row align="bottom" gutter={[12, 16]}>
        <Col flex="20%">
          <Form.Item
            name="engine_oil_added"
            label={
              <Text size="label4" transform="uppercase">
                Долито мл. двс
              </Text>
            }
          >
            <InputNumber disabled={isDisabled} min={0} className="w-100" size="large" />
          </Form.Item>
        </Col>
        <Col flex="20%">
          <Form.Item
            name="transmission_oil_added"
            label={
              <Text size="label4" transform="uppercase">
                Долито мл. кпп
              </Text>
            }
          >
            <InputNumber disabled={isDisabled} min={0} className="w-100" size="large" />
          </Form.Item>
        </Col>
        <Col flex="20%">
          <Form.Item
            name="hydraulic_tank_water_added"
            label={
              <Text size="label4" transform="uppercase">
                Долито мл. гидробак
              </Text>
            }
          >
            <InputNumber disabled={isDisabled} min={0} className="w-100" size="large" />
          </Form.Item>
        </Col>
        <Col flex="20%">
          <Form.Item
            name="battery_water_added"
            label={
              <Text size="label4" transform="uppercase">
                Долито мл. акб
              </Text>
            }
          >
            <InputNumber disabled={isDisabled} min={0} className="w-100" size="large" />
          </Form.Item>
        </Col>
        <Col flex="20%">
          <Form.Item
            name="fuel_added"
            label={
              <Text size="label4" transform="uppercase">
                Заправка диз. топл. л.
              </Text>
            }
          >
            <InputNumber disabled={isDisabled} min={0} className="w-100" size="large" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default StateFields;
