import { ReactElement } from "react";
import { Space } from "antd";
import clsx from "clsx";

import { isGroup } from "core/shared/dashboard";

import { ICellProps } from "..";

import "./styles.scss";

function name({ record }: ICellProps): ReactElement {
  if (isGroup(record)) {
    return <div />;
  }
  return (
    <Space
      className="w-100"
      style={{
        overflowWrap: "anywhere",
      }}
      direction="horizontal"
      size={16}
    >
      <div
        className={clsx("task-badge", {
          planned: record.is_it_plan,
        })}
      />
      {record.request_description}
    </Space>
  );
}

export default name;
