import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useContext } from "react";

import { useGetDashboardShiftStatisticTasksByTeamsVolsQuery } from "core/app/api/VOLS";
import { getDashboardShiftStatisticTasksByTeam } from "core/shared/dashboard/api/getDashboardShiftStatisticTasksByTeam";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

export function useDashboardShiftStatisticTasksByTeamsVols() {
  const { queryString, teamId } = useContext(ShiftContext);
  const { data } = useGetDashboardShiftStatisticTasksByTeamsVolsQuery(queryString ?? skipToken);
  return getDashboardShiftStatisticTasksByTeam(data, teamId);
}
