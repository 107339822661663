import { ReactElement } from "react";
import { Card } from "antd";

import { useUnit } from "core/shared/unit/useUnit";

import PspaMaintenanceStatistic from "./PspaMaintenance";
import SlpiMaintenanceStatistic from "./SlpiMaintenance";
import OossaMaintenanceStatistic from "./OossaMaintenance";
import LsoMaintenanceStatistic from "./LsoMaintenance";
import LkuMaintenanceStatistic from "./LkuMaintenance";
import VolsMaintenanceStatistic from "./VolsMaintenanceStatistic";

/** Статистика по ТО. */
export default function MaintenanceStatistic(): ReactElement {
  const unit = useUnit();

  return (
    <Card className="maintenance">
      {unit?.isPSPA() && <PspaMaintenanceStatistic />}
      {unit?.isSLPI() && <SlpiMaintenanceStatistic />}
      {unit?.isLSO() && <LsoMaintenanceStatistic />}
      {unit?.isLKU() && <LkuMaintenanceStatistic />}
      {unit?.isVOLS() && <VolsMaintenanceStatistic />}
      {unit?.isOOSSA() && <OossaMaintenanceStatistic />}
    </Card>
  );
}
