import { message } from "antd";
import { get } from "lodash";
import { useEffect, useRef } from "react";

/** Найти первую непустую строку.
 * На вход может подаваться как отдельная строка, так и массив строк,
 * и даже массив смешанных элементов, каждый из которых либо строка, либо массив строк,
 * либо такой же смешанный элемент. */
function findFirstNotEmptyString(value: unknown): string | undefined {
  if (typeof value === "string") {
    return value;
  }
  if (Array.isArray(value)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const valueItem of value) {
      const v = findFirstNotEmptyString(valueItem);
      if (v) return v;
    }
  }
  return undefined;
}

/** Выдать всплывающее сообщение об ошибке, если бэкенд вернул данные об ошибке.
 * @param backendResult - результат выполнения запроса к бекенду (RtkQuery).
 * @param messagePrefix - префикс сообщения об ошибке либо целиком сообщение, когда нет более детальной информации.
 * Передайте undefined для сообщения по умолчанию ("Ошибка на сервере").
 * @param errorDataShortPathes - массив кратких (внутри error.data) путей к свойствам,
 * которые могут содержать детальный текст ошибки (либо строку, либо массив строк).
 * Для формирования полного текста ошибки будет использоваться первый найденный непустой текст ошибки
 * из всех перечисленных путей, а если свойства окажутся массивами строк, то первый непустой элемент.
 * Дополнительно, будет производиться поиск в error.data.detail (т.е. можно не указывать путь "detail").
 */
export function useBackendError(
  backendResult: { isLoading: boolean; isError: boolean },
  messagePrefix = "Ошибка на сервере",
  errorDataShortPathes: string[] = []
) {
  const errorDataShortPathesRef = useRef(errorDataShortPathes);
  useEffect(() => {
    errorDataShortPathesRef.current = errorDataShortPathes;
  }, [errorDataShortPathes]);

  useEffect(() => {
    if (!backendResult.isLoading && backendResult.isError) {
      const propsPathes = [...errorDataShortPathesRef.current, "detail"].map((path) => `error.data.${path}`);
      const propsValues = propsPathes.map((path) => get(backendResult, path));
      const firstMessage = findFirstNotEmptyString(propsValues);
      const errorMessage = [messagePrefix, firstMessage].filter(Boolean).join(": ");

      message.error(errorMessage);
    }
  }, [backendResult, messagePrefix]);
}
