import { ReactElement } from "react";
import { Table } from "antd";

import { useDashboardShiftStatisticTasksByTeamsPspa } from "core/shared/dashboard/api/useDashboardShiftStatisticTasksByTeamsPspa";
import { IDashboardShiftStatisticTasksByTeamsPspaTask } from "core/app/api/interfaces";
import { IGroup } from "core/shared/dashboard";
import useUiPermissions from "core/shared/permissions/hooks/useUiPermissions";
import { EffortTypeEnum } from "core/models/enums";

import Equipment from "../Fields/Equipment";
import ClosingNote from "../Fields/ClosingNote";
import Type from "../Fields/Type";
import Effort from "../Fields/Effort";
import ElapsedTime from "../Fields/ElapsedTime";
import { Comment } from "../Fields/Comment";
import RequestDescription from "../Fields/RequestDescription";

type TTableRecord = IDashboardShiftStatisticTasksByTeamsPspaTask | IGroup<IDashboardShiftStatisticTasksByTeamsPspaTask>;

const columns = [
  {
    title: "ЕД. ОБОРУД.",
    dataIndex: "equipment",
    render: (_: string, record: TTableRecord) => <Equipment record={record} />,
    width: 150,
  },
  {
    title: "НАИМ. ОБОРУД.",
    dataIndex: "equipment_type_name",
  },
  {
    title: "ТИП",
    dataIndex: "type",
    render: (_: string, record: TTableRecord) => <Type record={record} />,
  },
  {
    title: "ОПИСАНИЕ ЗАДАЧИ",
    dataIndex: "request_description",
    width: 300,
    render: (_: string, record: TTableRecord) => <RequestDescription record={record} />,
  },
  {
    title: "СТАТУС",
    dataIndex: "status",
    width: 80,
  },
  {
    title: "КОММЕНТАРИЙ",
    dataIndex: "request_comment",
    render: (_: string, record: TTableRecord) => <Comment record={record} />,
  },
  {
    title: "ПРИМЕЧАНИЕ К РАЗРЕШЕНИЮ",
    dataIndex: "closing_note",
    render: (_: string, record: TTableRecord) => <ClosingNote record={record} />,
  },
  {
    title: "ЗАГРУЗКА (ПЛАН)",
    dataIndex: "effort",
    render: (_: string, record: TTableRecord) => <Effort record={record} effortType={EffortTypeEnum.HOURS} />,
  },
  {
    title: "ЗАГРУЗКА (ФАКТ)",
    dataIndex: "elapsed_time",
    render: (_: string, record: TTableRecord) => <ElapsedTime record={record} effortType={EffortTypeEnum.HOURS} />,
  },
];

export function StatisticByTeamPspaTable(): ReactElement {
  const dataSource = useDashboardShiftStatisticTasksByTeamsPspa();
  const { CAN_SEE_SHIFT_FACT } = useUiPermissions("dashboard");

  let columnsFiltered = columns;
  if (!CAN_SEE_SHIFT_FACT) {
    columnsFiltered = columns.filter((item) => item.dataIndex !== "elapsed_time");
  }

  return (
    <Table
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 0,
      }}
      size="small"
      dataSource={dataSource}
      rowKey="id"
      columns={columnsFiltered}
    />
  );
}
