import dayjs from "dayjs";
import { Tooltip } from "antd";

import { Button } from "core/lib";
import { IMaintenanceControl, ITask } from "core/app/api/interfaces";
import { useGetEquipmentMaintenanceQuery, useUpdatePspaTaskMutation } from "core/app/api";
import { useGetTransportMaintenanceQuery } from "core/app/api/api";
import { useBackendError } from "core/shared/useBackendError";

import { Field } from "../Fields/Field";

interface MaintenanceControlProps {
  task: ITask;
}

/** Блок элементов для добавления задачи в наряд-задание
 * из карточки задачи АВР, ЗНО, доп.работ
 * для стационарного оборудования ЦГПС ЗФ (это ПСПА ЗФ). */
export function MaintenanceControl({ task }: MaintenanceControlProps) {
  const transportId = task.transport_id;
  const equipmentId = transportId ? null : task.equipment_id; // в случае навесного оборудования, нужно будет по нашей кнопке создавать ТО на транспорт, а не на оборудование
  const { data: equipmentMaintenance } = useGetEquipmentMaintenanceQuery(`${equipmentId}`, { skip: !equipmentId });
  const { data: transportMaintenance } = useGetTransportMaintenanceQuery(`${transportId}`, { skip: !transportId });
  const maintenanceControl: IMaintenanceControl | undefined = equipmentMaintenance || transportMaintenance;

  const teamId = task.team_id;
  const teamDate = task.team?.date;

  const [updateTaskRequest, updateTaskResult] = useUpdatePspaTaskMutation();
  function addTaskToTeam() {
    updateTaskRequest({ id: maintenanceControl?.next_maintenance?.task_id, team_id: teamId, planned_date: teamDate });
  }
  useBackendError(updateTaskResult, "Ошибка добавления в наряд-задание", ["team_id"]);

  const isTeamDisbanded = task.team?.disbanded;

  const disabledCause: string[] = []; // список причин, по которым наша кнопка запрещена к нажатию

  if (!teamId) disabledCause.push("бригада не указана");
  else if (isTeamDisbanded) disabledCause.push("бригада расформирована");
  else if (!teamDate) disabledCause.push("дата бригады не указана");

  if (!maintenanceControl?.next_maintenance?.execution_date) disabledCause.push("не определена следующая дата ТО");
  else if (!maintenanceControl?.next_maintenance?.task_id) disabledCause.push("не найдена задача для следующего ТО");

  if (teamId && maintenanceControl?.next_maintenance?.team_id === teamId)
    disabledCause.push(
      `задача уже назначена на ${dayjs(maintenanceControl?.next_maintenance?.execution_date)?.format("DD.MM.YYYY")}`
    );

  const disabledTitle = disabledCause.length
    ? `Добавление в наряд-задание не доступно, т.к. ${disabledCause.join(", ")}`
    : "";
  const isDisabledButton = disabledTitle !== "";

  return (
    <>
      <Field
        label="Дата последнего ТО"
        value={
          maintenanceControl?.last_maintenance?.execution_date
            ? dayjs(maintenanceControl.last_maintenance.execution_date).format("DD MMMM YYYY")
            : "не определена"
        }
      />
      <Field
        label="Дата следующего ТО"
        value={
          maintenanceControl?.next_maintenance?.execution_date
            ? dayjs(maintenanceControl.next_maintenance.execution_date).format("DD MMMM YYYY")
            : "не определена"
        }
      />
      <br />
      <Tooltip title={disabledTitle}>
        <Button
          type="primary"
          className="w-100"
          onClick={() => addTaskToTeam()}
          disabled={isDisabledButton}
          loading={updateTaskResult.isLoading}
        >
          Добавить в наряд-задание
        </Button>
      </Tooltip>
    </>
  );
}
