import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { formatVolsLineName } from "core/shared/vols/tools";

import { Field } from "../Field";

interface TVolsLineProps {
  task: ITask;
}

function VolsLine({ task }: TVolsLineProps): ReactElement {
  return <Field label="Линия" value={formatVolsLineName(task.line)} />;
}

export { VolsLine };
