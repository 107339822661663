import { ReactElement, useContext } from "react";

import { StatisticTeamSelector } from "core/shared/dashboard/ui/TeamSelector";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { useGetDashboardShiftStatisticTasksByTeamsVolsQuery } from "core/app/api/VOLS";

export default function StatisticTeamSelectorVols(): ReactElement {
  const { queryString } = useContext(ShiftContext);
  const { data, isSuccess } = useGetDashboardShiftStatisticTasksByTeamsVolsQuery(queryString, { skip: !queryString });

  if (!(isSuccess && data)) {
    return <div />;
  }

  return <StatisticTeamSelector teams={data} />;
}
