import { Form, Row, Spin } from "antd";

import { useEtoTask } from "core/shared/task/useTask";
import ErrorMessage from "core/widgets/CreateTaskModal/ErrorMessage";

import { AssistanceCarMaintenanceContext, FormPagesEnum } from "../AssistanceCarMaintenanceContext";
import { useAssistanceCarMaintenanceContextValues } from "../hooks/useAssistanceCarMaintenanceContextValues";

import TaskTitle from "./Header/TaskTitle";
import AcmBody from "./Body";
import Footer from "./Footer";
import Header from "./Header";
import { SignatureForm } from "./Body/SignatureForm";

function Maintenance() {
  const contextValues = useAssistanceCarMaintenanceContextValues();
  const { showErrorMessage, page, signatureSide } = contextValues;
  const showSignatureForm = page === FormPagesEnum.SIGNATURE && signatureSide;

  const { data: task } = useEtoTask();
  const [form] = Form.useForm();

  form.getFieldsError();

  if (!task) {
    return (
      <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <AssistanceCarMaintenanceContext.Provider value={contextValues}>
      {task && (
        <Form preserve initialValues={task} className="w-100 assistance-car-modal" form={form} layout="vertical">
          <TaskTitle task={task} />
          {showErrorMessage && (
            <div className="mb-3">
              <ErrorMessage message="Заполните все чек-листы и обязательные поля" description="" />
            </div>
          )}
          {page === FormPagesEnum.TASK && (
            <>
              <Header task={task} />
              <AcmBody task={task} />
              <Footer task={task} />
            </>
          )}
          {showSignatureForm && <SignatureForm task={task} />}
        </Form>
      )}
    </AssistanceCarMaintenanceContext.Provider>
  );
}

export default Maintenance;
