import { IDashboardShiftStatisticTasksByTeamsLkuTask } from "core/app/api/LKU/interfaces";
import { IDashboardShiftStatisticTasksByTeamsLsoTask } from "core/app/api/LSO/interfaces";
import { IDashboardShiftStatisticTasksByTeamsVolsTask } from "core/app/api/VOLS/interfaces";
import {
  IDashboardShiftStatisticTasksByTeamsSlpiTask,
  IDashboardShiftStatisticTasksByTeamsPspaTask,
  IDashboardShiftStatisticTasksByTeamsOossaTask,
} from "core/app/api/interfaces";

type TTeamTask =
  | IDashboardShiftStatisticTasksByTeamsLsoTask
  | IDashboardShiftStatisticTasksByTeamsLkuTask
  | IDashboardShiftStatisticTasksByTeamsVolsTask
  | IDashboardShiftStatisticTasksByTeamsSlpiTask
  | IDashboardShiftStatisticTasksByTeamsPspaTask
  | IDashboardShiftStatisticTasksByTeamsOossaTask;

export interface IGroup<T extends TTeamTask> {
  name: string;
  id: string;
  type: string;
  children?: T[];
}

export const isGroup = (record: IGroup<TTeamTask> | TTeamTask): record is IGroup<TTeamTask> => {
  if (record.type === "group") {
    return true;
  }
  return false;
};
