import { IDashboardShiftStatisticTasksByTeamsLkuTask } from "core/app/api/LKU/interfaces";
import { IGroup, isGroup } from "core/shared/dashboard";

interface CellProps {
  record: IDashboardShiftStatisticTasksByTeamsLkuTask | IGroup<IDashboardShiftStatisticTasksByTeamsLkuTask>;
}
export function LkuDepartment({ record }: CellProps) {
  if (isGroup(record)) {
    return <div />;
  }

  return <span>{record.customer_department}</span>;
}
