import { ReactElement } from "react";
import { Space, Tooltip } from "antd";

import { UrgencyEnum } from "core/app/api/interfaces";
import icCritical from "core/images/urgency-critical.svg";
import icHigh from "core/images/urgency-high.svg";
import icMedium from "core/images/urgency-medium.svg";
import icLow from "core/images/urgency-low.svg";
import Text, { TSize, TBoldness } from "core/lib/Text/Text";

interface TaskUrgencyProps {
  requestPriority: UrgencyEnum | null;
  showText?: boolean;
  size?: TSize;
  short?: boolean;
  boldness?: TBoldness;
}

/** Приоритет (срочность) задания. Иконка и опционально текст. */
export default function TaskUrgency(props: TaskUrgencyProps): ReactElement {
  const { requestPriority, showText: shouldShowText, size, short: isShort, boldness } = props;
  if (requestPriority === null) {
    return <div />;
  }

  let imgUrgency = icLow;
  let text = isShort ? "Низ" : "Низкий";
  switch (requestPriority) {
    case UrgencyEnum.Low:
      imgUrgency = icLow;
      text = isShort ? "Низ" : "Низкий";
      break;
    case UrgencyEnum.Medium:
      imgUrgency = icMedium;
      text = isShort ? "Сред" : "Средний";
      break;
    case UrgencyEnum.High:
      imgUrgency = icHigh;
      text = isShort ? "Выс" : "Высокий";
      break;
    case UrgencyEnum.Critical:
      imgUrgency = icCritical;
      text = isShort ? "Крит" : "Критичный";
      break;
    default:
      break;
  }

  if (!shouldShowText) {
    return (
      <Tooltip title={`Приоритет - ${text}`}>
        <img style={{ verticalAlign: "middle" }} src={imgUrgency} alt="urgent" />
      </Tooltip>
    );
  }
  return (
    <Space>
      <img style={{ verticalAlign: "middle" }} src={imgUrgency} alt="urgent" />
      <Text boldness={boldness || "regular"} size={size || "label2"}>
        {text}
      </Text>
    </Space>
  );
}
