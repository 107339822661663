import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
// eslint-disable-next-line import/no-unresolved
import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { apiSlice } from "../api";
import { ITask } from "../interfaces";

export async function handleUpdateTaskQueryStarted(
  { rtkQueryCacheKey, ...taskUpdate }: any,
  {
    dispatch,
    queryFulfilled,
    getState,
  }: MutationLifecycleApi<any, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, any, "api">
) {
  try {
    const updatedTask = await queryFulfilled;
    dispatch(
      apiSlice.util.updateQueryData("getOpenTasks", rtkQueryCacheKey, (cachedTasks) => {
        const cachedTaskToUpdate = cachedTasks.results.find((cachedTask: ITask) => cachedTask.id === taskUpdate.id);
        if (cachedTaskToUpdate) {
          Object.assign(cachedTaskToUpdate, updatedTask.data);
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export const TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE = [
  "tasks",
  "shifttask",
  "tasks-filtered",
  "kanban-board",
  "month-plan",
  "tasks/statistics-by-days",
  "dashboard/shift-statistic/tasks",
  "dashboard/shift-statistic/tasks/by-equipment-types",
  "dashboard/shift-statistic/tasks/by-objects/slpi",
  "equipments/maintenance",
  "transport/maintenance",
] as const;
