import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useContext } from "react";

import { useGetDashboardShiftStatisticTasksByTeamsLkuQuery } from "core/app/api/LKU";
import { getDashboardShiftStatisticTasksByTeam } from "core/shared/dashboard/api/getDashboardShiftStatisticTasksByTeam";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

export function useDashboardShiftStatisticTasksByTeamsLku() {
  const { queryString, teamId } = useContext(ShiftContext);
  const { data } = useGetDashboardShiftStatisticTasksByTeamsLkuQuery(queryString ?? skipToken);
  return getDashboardShiftStatisticTasksByTeam(data, teamId);
}
