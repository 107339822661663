import { Input, Form } from "antd";
import { ReactElement } from "react";

const { TextArea } = Input;

interface UsedMaterialsTextAreaProps {
  disabled: boolean;
  isSGPS?: boolean;
}

function UsedMaterialsTextArea({ disabled, isSGPS = false }: UsedMaterialsTextAreaProps): ReactElement {
  return (
    <Form.Item label={`ИСПОЛЬЗУЕМЫЕ МАТЕРИАЛЫ${isSGPS ? " (Новые)" : ""}`} name="used_materials">
      <TextArea rows={5} disabled={disabled} />
    </Form.Item>
  );
}

export default UsedMaterialsTextArea;
