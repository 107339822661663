import { ReactElement } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import qs from "qs";
import { Col, Empty, Form, Row } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { VolsEquipmentTypeCodeEnum } from "core/app/api/VOLS/enums";
import { useUnit } from "core/shared/unit/useUnit";
import { useGetDashboardVolsMaintenancePlanFactStatisticQuery } from "core/app/api/VOLS";

import { Bars } from "../../Bars";
import { getStatAmount } from "..";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function BarsVols(): ReactElement {
  const unit = useUnit();
  const yearDate = Form.useWatch("year");
  const monthDate = Form.useWatch("month");
  const customerId = Form.useWatch("customer_id");
  const customerDepartmentId = Form.useWatch("customer_department_id");

  const queryParams = {
    unit_id: unit?.id,
    ...(yearDate && { year: yearDate.year() }),
    ...(monthDate && { month: monthDate.month() + 1 }),
    ...(customerId && { customer_id: customerId }),
    ...(customerDepartmentId && { customer_department_id: customerDepartmentId }),
  };
  const { data, isSuccess } = useGetDashboardVolsMaintenancePlanFactStatisticQuery(
    unit?.isVOLS() ? qs.stringify(queryParams) : skipToken
  );

  if (!isSuccess) {
    return <Empty />;
  }

  const isCurrentDate = yearDate?.year() === new Date().getFullYear() && monthDate?.month() === new Date().getMonth();

  return (
    <Row>
      <Col span={12}>
        <Bars
          title="Кроссы, шт"
          month={getStatAmount(data.plans_for_the_month, VolsEquipmentTypeCodeEnum.CROSS)}
          day={getStatAmount(data.plans_for_the_day, VolsEquipmentTypeCodeEnum.CROSS)}
          fact={getStatAmount(data.facts_for_the_day, VolsEquipmentTypeCodeEnum.CROSS)}
          isCurrentDate={isCurrentDate}
        />
      </Col>
      <Col span={12}>
        <Bars
          title="Линии, м"
          month={getStatAmount(data.plans_for_the_month, VolsEquipmentTypeCodeEnum.LINE)}
          day={getStatAmount(data.plans_for_the_day, VolsEquipmentTypeCodeEnum.LINE)}
          fact={getStatAmount(data.facts_for_the_day, VolsEquipmentTypeCodeEnum.LINE)}
          isCurrentDate={isCurrentDate}
        />
      </Col>
    </Row>
  );
}
