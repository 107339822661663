import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { TSlpiDirectObjectLight, SlpiWorkTypeGroupEnum } from "core/app/api/interfaces";
import { useGetSlpiDirectObjectsLightQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import { useWorkType } from "core/shared/slpi/useWorkType";

export function Object({ completedMaintenance = false }): ReactElement {
  const [unitId] = useUnitId();
  const form = Form.useFormInstance();
  const departmentId = Form.useWatch("departmentId");
  const customerId = Form.useWatch("customerId");
  const workTypeId = Form.useWatch("workTypeId");
  const workType = useWorkType(workTypeId);

  const queryParams: Record<string, string | number | boolean> = {
    completed_maintenance: completedMaintenance,
    ...(unitId && { unit_id: unitId }),
    ...(customerId && { customer_id: customerId }),
    ...(departmentId && { customer_department_id: departmentId }),
  };

  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetSlpiDirectObjectsLightQuery(queryString);

  const options = (data || []).map((item: TSlpiDirectObjectLight) => ({
    label: item.inventory_number ? `${item.name} ${item.inventory_number}` : item.name,
    value: item.id,
  }));

  const handleChange = (val: number) => {
    const object = (data || []).find((item) => item.id === val);
    if (object) {
      form.setFieldValue("customerId", object.customer);
      form.setFieldValue("departmentId", object.customer_department);
    }
    form.setFieldValue("systemComplexId", null);
  };

  const required = workType?.group === SlpiWorkTypeGroupEnum.WORK;

  return (
    <Form.Item
      label="ОБЪЕКТ"
      name="objectId"
      colon={false}
      required={required}
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleChange}
      />
    </Form.Item>
  );
}
