import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "core/app/store";
import { setOpinTaskDraftId } from "core/app/store/task-slice";

export function useOpinTaskDraftId(): [number | null, (value: number | null) => void] {
  const dispatch = useAppDispatch();
  const { opinTaskDraftId } = useAppSelector((state) => state.task);

  const set = useCallback(
    (value: number | null) => {
      dispatch(setOpinTaskDraftId(value));
    },
    [dispatch]
  );

  return [opinTaskDraftId, set];
}
