import {
  EtoTaskAttachmentCategoryEnum,
  IAttachment,
  IEtoTask,
  ITask,
  TaskAttachmentCategoryEnum,
} from "core/app/api/interfaces";

import { UnitServiceCodesEnum } from "../unit/types";

export function getAttachmentsCategoryCount(
  attachments: IAttachment[] | undefined,
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum
) {
  return attachments?.reduce((accumulator, currentValue) => {
    if (currentValue.category === category) {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);
}

export function hasEmptyAttachmentsCategory(attachments: IAttachment[] | undefined, hasVandalActions = false) {
  const categories = Object.values(TaskAttachmentCategoryEnum).filter(
    (category) =>
      category !== TaskAttachmentCategoryEnum.FilesByTask &&
      (hasVandalActions || category !== TaskAttachmentCategoryEnum.Vandalism)
  );

  for (let index = 0; index < categories.length; index += 1) {
    const category = categories[index];
    if (getAttachmentsCategoryCount(attachments, category) === 0) {
      return true;
    }
  }
  return false;
}

/** Группа файлов (например, фотографий) в карточке ТО. */
export const mediaCategoryTitles = {
  work_place: "МЕСТО РАБОТ",
  before_or_in_progress: "ДО / В ПРОЦЕССЕ",
  after_completion: "ПОСЛЕ",
  files_by_task: "ФАЙЛЫ ПО ЗАДАЧЕ",
  shift_start: "НАЧАЛО СМЕНЫ",
  shift_end: "КОНЕЦ СМЕНЫ",
  other: "ОСТАЛЬНЫЕ",
  vandalism: "ВАНДАЛЬНЫЕ ДЕЙСТВИЯ",
};

export const isTaskValidForAssignment = (task: ITask): boolean =>
  !!task.request_status || !task.request_category || !task.request_category.required_customer_approval;

export function isSlpiTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodesEnum.SLPI_KF || task?.service?.code === UnitServiceCodesEnum.SLPI_ZF;
}

export function isPspaTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodesEnum.PSPA_KF || task?.service?.code === UnitServiceCodesEnum.PSPA_ZF;
}

export function isPspaKfTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodesEnum.PSPA_KF;
}

export function isPspaZfTask(task?: ITask | IEtoTask): task is ITask {
  return task?.service?.code === UnitServiceCodesEnum.PSPA_ZF;
}

export function isOossaTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodesEnum.OOSSA;
}

export function isLsoTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodesEnum.LSO;
}

export function isLkuTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodesEnum.LKU;
}

export function isVolsTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodesEnum.VOLS;
}
