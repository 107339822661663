import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface TVolsFactVolumeProps {
  task: ITask;
}

function VolsFactVolume({ task }: TVolsFactVolumeProps): ReactElement {
  return <Field label="Фактическое количество м" value={task.actual_volume?.toString()} />;
}

export { VolsFactVolume };
