import { Tabs as AntTabs } from "antd";
import type { TabsProps } from "antd";

import { useTask } from "core/shared/task/useTask";
import { useUnit } from "core/shared/unit/useUnit";
import Tab from "core/widgets/TaskModals/shared/Tab/FormControlled";

import PspaMaintenanceExecution from "../MaintenanceExecution";

interface Props {
  selectedTab: string;
  setSelectedTab: (arg: string) => void;
}

/** Закладка на карточке технического обслуживания (ТО).
 * Соответствует виду оборудования (системе) в навесном оборудовании ПСПА. */
export function PspaTabs({ selectedTab, setSelectedTab }: Props) {
  const unit = useUnit();
  const { data: task } = useTask();

  if (!task?.maintenance_execution) {
    return null;
  }
  const items: TabsProps["items"] = task.maintenance_execution.map((item, index) => ({
    key: `${item.id}`,
    children: (
      <PspaMaintenanceExecution maintenanceExecution={item} index={index} showVandalActionsSwitch={unit?.isPSPAZF()} />
    ),
    label: <Tab maintenanceExecution={item} index={index} />,
    forceRender: true,
  }));

  return <AntTabs activeKey={selectedTab} onChange={(key) => setSelectedTab(key)} items={items} />;
}
