import { ReactElement, useEffect } from "react";
import { Tabs, Drawer } from "antd";

import { EtoTaskAttachmentCategoryEnum, IEtoTask } from "core/app/api/interfaces";
import { useGetTaskAttachmentsQuery } from "core/app/api";
import { MediaContext, useMediaContextInitialValue } from "core/shared/task/context/mediaContext";
import { getAttachmentsCategoryCount } from "core/shared/task";
import { Category } from "core/widgets/TaskModals/shared/Media/Category";

import { MediaGallery } from "./MediaGallery";
import { SaveAllBtn } from "./SaveAllBtn";
import { Badge } from "./Badge";

interface MediaProps {
  task: IEtoTask;
}

/** Закладки и список медиа-файлов в карточке задания. */
export default function AcmMedia({ task }: MediaProps): ReactElement {
  const { data } = useGetTaskAttachmentsQuery(task.id);
  const contextValue = useMediaContextInitialValue();
  const { setTaskId, setSelectedMediaId, selectedMediaId } = contextValue;

  useEffect(() => {
    if (task) {
      setTaskId(task.id);
    }
  }, [setTaskId, task]);

  const items = [
    {
      label: (
        <Badge count={getAttachmentsCategoryCount(data, EtoTaskAttachmentCategoryEnum.ShiftStart)}>НАЧАЛО СМЕНЫ</Badge>
      ),
      key: "item-1",
      children: <Category task={task} category={EtoTaskAttachmentCategoryEnum.ShiftStart} />,
    },
    {
      label: (
        <Badge count={getAttachmentsCategoryCount(data, EtoTaskAttachmentCategoryEnum.ShiftEnd)}>КОНЕЦ СМЕНЫ</Badge>
      ),
      key: "item-2",
      children: <Category task={task} category={EtoTaskAttachmentCategoryEnum.ShiftEnd} />,
    },
    {
      label: <Badge count={getAttachmentsCategoryCount(data, EtoTaskAttachmentCategoryEnum.Other)}>ОСТАЛЬНЫЕ</Badge>,
      key: "item-3",
      children: <Category task={task} category={EtoTaskAttachmentCategoryEnum.Other} />,
    },
  ];
  return (
    <MediaContext.Provider value={contextValue}>
      <Tabs items={items} tabBarExtraContent={<SaveAllBtn taskId={task.id} />} />
      <Drawer
        open={!!selectedMediaId}
        onClose={() => {
          setSelectedMediaId(null);
        }}
        width="100VW"
        zIndex={2000}
      >
        {data && <MediaGallery attachments={data} />}
      </Drawer>
    </MediaContext.Provider>
  );
}
