import { Input, Form } from "antd";
import { ReactElement } from "react";

const { TextArea } = Input;

interface UsedMaterialsTextAreaProps {
  disabled: boolean;
}

function RepairedUsedMaterialsTextArea({ disabled }: UsedMaterialsTextAreaProps): ReactElement {
  return (
    <Form.Item label="ИСПОЛЬЗУЕМЫЕ МАТЕРИАЛЫ (Б/У)" name="repaired_used_materials">
      <TextArea rows={5} disabled={disabled} />
    </Form.Item>
  );
}

export default RepairedUsedMaterialsTextArea;
