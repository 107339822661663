import { Tag } from "antd";

import { LKU_TAG_COLORS } from "core/app/api/LKU/constants";
import { IDashboardShiftStatisticTasksByTeamsLkuTask } from "core/app/api/LKU/interfaces";
import { IGroup, isGroup } from "core/shared/dashboard";

interface CellProps {
  record: IDashboardShiftStatisticTasksByTeamsLkuTask | IGroup<IDashboardShiftStatisticTasksByTeamsLkuTask>;
}
export function LkuEquipmentType({ record }: CellProps) {
  if (isGroup(record)) {
    return <div />;
  }

  return <Tag color={LKU_TAG_COLORS[record.code_enum]}>{record.equipment_type_name}</Tag>;
}
