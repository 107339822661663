import { ReactElement } from "react";

import { useUnit } from "core/shared/unit/useUnit";

import { ByEquipmentTypesPspa } from "./ByEquipmentTypesPspa";
import { ByObjectsSlpi } from "./ByObjectsSlpi";
import ByObjectsLso from "./ByObjectsLso";
import ByObjectsLku from "./ByObjectsLku";
import { ByObjectsOossa } from "./ByObjectsOossa";
import ByCustomDepartmentsVols from "./ByCustomDepartmentsVols";

export default function StatisticByEquipmentTypesOrObjects(): ReactElement {
  const unit = useUnit();

  return (
    <div style={{ width: "100%" }}>
      {unit?.isPSPA() && <ByEquipmentTypesPspa />}
      {unit?.isSLPI() && <ByObjectsSlpi />}
      {unit?.isLSO() && <ByObjectsLso />}
      {unit?.isLKU() && <ByObjectsLku />}
      {unit?.isVOLS() && <ByCustomDepartmentsVols />}
      {unit?.isOOSSA() && <ByObjectsOossa />}
    </div>
  );
}
