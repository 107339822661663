import { Col, Form, FormInstance, Input, Row } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";
import { useUnit } from "core/shared/unit/useUnit";

import { ElapsedTime } from "../../shared/ElapsedTime";
import { VandalActionsSwitch } from "../../shared/Fields/VandalActionsSwitch";
import RepairedUsedMaterialsTextArea from "../../shared/RepairedUsedMaterialsTextArea";
import UsedMaterialsTextArea from "../../shared/UsedMaterialsTextArea";

const { TextArea } = Input;

interface BodyProps {
  form: FormInstance;
  task: ITask;
}

function Body({ form, task }: BodyProps): ReactElement {
  const isDisabled = useDisabled();
  const unit = useUnit();

  const shouldShowMultiplier = !(unit?.isSLPI() || unit?.isLSO() || unit?.isLKU());

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <ElapsedTime useMultiplier={shouldShowMultiplier} disabled={isDisabled} />
          </Col>
        </Row>
        <Form.Item
          label="КОММЕНТАРИЙ"
          name="request_comment"
          required
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(new Error("Пожалуйста, введите КОММЕНТАРИЙ"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TextArea rows={5} disabled={isDisabled} />
        </Form.Item>

        {unit?.isPSPAKF() && <UsedMaterialsTextArea isSGPS={unit?.isPSPAKF()} disabled={isDisabled} />}
        {unit?.isPSPAKF() && <RepairedUsedMaterialsTextArea disabled={isDisabled} />}
        {unit?.isPSPAZF() && task?.is_mounted && <VandalActionsSwitch isDisabled={isDisabled} />}
      </Col>
    </Row>
  );
}

export default Body;
