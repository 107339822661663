import { Modal, Row, Spin } from "antd";
import { ReactElement } from "react";

import { TaskDiscriminatorEnum, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useAppDispatch, useAppSelector } from "core/app/store";
import { setFindOrCreate } from "core/app/store/find-or-create-slice";
import { useOpinTaskDraftId } from "core/shared/task/useOpinTaskDraftId";
import { useSignatureStatus } from "core/shared/task/useSignatureStatus";
import { useTask } from "core/shared/task/useTask";
import { useTaskId } from "core/shared/task/useTaskId";
import { CreateOpinForm } from "core/widgets/CreateOpinForm";
import OossaMaintenanceTaskContent from "core/widgets/Oossa/OossaMaintenanceTaskContent";

import AssistanceCarTaskModal from "../AssistanceCarTaskModal";
import LsoTaskModal from "../LsoTaskModal";

import PspaMountedTaskForm from "./Mounted";
import { OpinTaskModal } from "./Opin";
import OtherTaskModal from "./Other";
import RecoveryTaskModal from "./Recovery";
import SgpsMountedTaskForm from "./SGPS/Mounted";
import StationaryTaskModal from "./Stationary";

/**
 * Корневой компонент для всех задач открываемых в модальном окне.
 * Предоставляет непосредственно модальное окно, логику его открытия, загрузку задачи,
 * спиннер на период загрузки.
 * По типу загруженной задачи, определяет необходимый компонент для отображения содержимого окна.
 */
function TaskModal(): ReactElement {
  const [taskId, , moment, resetState] = useTaskId();
  const { findOrCreate } = useAppSelector((state) => state.findOrCreate);
  const { data: task } = useTask(null, moment);
  const [signatureStatus, setSignatureStatus] = useSignatureStatus();
  const [opinTaskDraftId, setOpinParentTaskId] = useOpinTaskDraftId();
  const dispatch = useAppDispatch();
  const isOpen = !!taskId || !!findOrCreate || !!opinTaskDraftId;

  const handleCancel = () => {
    if (opinTaskDraftId) {
      setOpinParentTaskId(null);
    } else if (signatureStatus) {
      setSignatureStatus(null);
    } else {
      resetState();
      dispatch(setFindOrCreate(null));
    }
  };
  return (
    <Modal maskClosable={false} destroyOnClose footer={null} open={isOpen} width={730} onCancel={handleCancel}>
      {/** @todo Наименования классов не отражают суть компонента, переименовать и отрефакторить стили */}
      <div className="task-modal recovery-task-modal maintenance-attached-equipment-modal">
        {opinTaskDraftId && <CreateOpinForm />}
        {taskId && task && !opinTaskDraftId && (
          <>
            {task.type === TaskTypesIdsEnum.MAINTENANCE && (
              <>
                {task.discriminator === TaskDiscriminatorEnum.LSO_TASK && <LsoTaskModal />}
                {task.discriminator === TaskDiscriminatorEnum.OOSSA_TASK && <OossaMaintenanceTaskContent />}
                {task.discriminator === TaskDiscriminatorEnum.SLPI_TASK && <StationaryTaskModal />}
                {task.discriminator === TaskDiscriminatorEnum.LKU_TASK && <StationaryTaskModal />}
                {task.discriminator === TaskDiscriminatorEnum.PSPA_TASK && (
                  <div>{task.is_mounted === true ? <PspaMountedTaskForm /> : <StationaryTaskModal />}</div>
                )}
                {task.discriminator === TaskDiscriminatorEnum.PSPA_KF_TASK && (
                  <div>{task.is_mounted === true ? <SgpsMountedTaskForm /> : <StationaryTaskModal />}</div>
                )}
                {task.discriminator === TaskDiscriminatorEnum.VOLS_TASK && <StationaryTaskModal />}
              </>
            )}
            {(task.type === TaskTypesIdsEnum.RECOVERY || task.type === TaskTypesIdsEnum.SERVICE_REQUEST) && (
              <RecoveryTaskModal />
            )}
            {task.type === TaskTypesIdsEnum.OTHER && <OtherTaskModal />}
            {task.type === TaskTypesIdsEnum.ETO && <AssistanceCarTaskModal />}
            {task.type === TaskTypesIdsEnum.OPIN && <OpinTaskModal />}
          </>
        )}
        {!task && (
          <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
            <Spin size="large" />
          </Row>
        )}
      </div>
    </Modal>
  );
}

export { TaskModal };
