import { ReactElement } from "react";

import { isGroup, IGroup } from "core/shared/dashboard";
import { IDashboardShiftStatisticTasksByTeamsLkuTask } from "core/app/api/LKU/interfaces";

type TTableRecord = IDashboardShiftStatisticTasksByTeamsLkuTask | IGroup<IDashboardShiftStatisticTasksByTeamsLkuTask>;

interface CellProps {
  record: TTableRecord;
}

export function LkuObject({ record }: CellProps): ReactElement {
  return <span>{isGroup(record) ? "" : record.lku_object || "НЕ УКАЗАН"}</span>;
}
