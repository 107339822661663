import { Form, FormInstance, Select } from "antd";
import qs from "qs";
import { ReactElement } from "react";

import { useGetItsmEquipmentMalfunctionQuery } from "core/app/api";
import { StateNamesEnum } from "core/app/api/interfaces";
import { useTask } from "core/shared/task/useTask";

interface MalfunctionSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function MalfunctionSelect({ disabled, form }: MalfunctionSelectProps): ReactElement {
  const elementId = Form.useWatch("element_id");
  const { data: task } = useTask();

  const queryParams = {
    equipment_type_element_id: elementId,
  };

  const { data: itsmEquipmentMalfunctions, isFetching } = useGetItsmEquipmentMalfunctionQuery(
    qs.stringify(queryParams)
  );

  let options: {
    label: string;
    value: number;
  }[] = [];

  if (itsmEquipmentMalfunctions) {
    options = itsmEquipmentMalfunctions.map((itsmEquipmentMalfunction) => ({
      label: itsmEquipmentMalfunction.name,
      value: itsmEquipmentMalfunction.id,
    }));
  }

  return (
    <Form.Item
      label="НЕИСПРАВНОСТЬ"
      name="malfunction_id"
      rules={[
        {
          validator: (_, value) => {
            const newState = form.getFieldValue("newState");
            if (newState === StateNamesEnum.Closed && !value && !!task?.itsm_equipment) {
              return Promise.reject(new Error("Пожалуйста, введите НЕИСПРАВНОСТЬ"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Select
        disabled={disabled || !task?.itsm_equipment}
        style={{ width: "100%" }}
        dropdownStyle={{ zIndex: 2000 }}
        options={options}
        loading={isFetching}
        dropdownMatchSelectWidth={650}
        placement="bottomRight"
        allowClear
        showSearch
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        onChange={() => {
          form.setFieldValue("malfunction_reason_id", null);
        }}
      />
    </Form.Item>
  );
}

export default MalfunctionSelect;
