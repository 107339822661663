import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetLkuObjectsQuery } from "core/app/api/LKU";
import { ILkuObject } from "core/app/api/LKU/interfaces";

import { FIELD_NAMES } from "../../constants";

export function LkuObjectField(): ReactElement {
  const [unitId] = useUnitId();
  const form = Form.useFormInstance();

  const queryParams = { is_active: true, ...(unitId && { unit_id: unitId }) };

  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetLkuObjectsQuery(queryString);

  const options = (data || []).map((item: ILkuObject) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = (value: number) => {
    form.setFieldsValue({
      [FIELD_NAMES.OBJECT]: value,
      [FIELD_NAMES.TECH_NODE]: undefined,
      [FIELD_NAMES.EQUIPMENT]: undefined,
    });
  };

  return (
    <Form.Item label="ОБЪЕКТ" name={FIELD_NAMES.OBJECT} colon={false}>
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleChange}
      />
    </Form.Item>
  );
}
