import { ReactElement, useState, useCallback } from "react";
import { Row, Col, Button, Modal } from "antd";

import Text from "core/lib/Text/Text";

interface FieldProps {
  number: string;
  requestDescription?: string;
}

function Number({ number, requestDescription }: FieldProps): ReactElement {
  const [isOpenTaskInfo, setOpenTaskInfo] = useState(false);
  const handleTaskClick = useCallback(() => {
    setOpenTaskInfo(true);
  }, []);

  const handleCloseTaskInfo = useCallback(() => {
    setOpenTaskInfo(false);
  }, []);

  return (
    <Row gutter={4}>
      <Col>
        <Text size="label2">Номер заявки:</Text>
      </Col>
      <Col>
        <Text className="blue" size="label2" boldness="black">
          {!requestDescription ? (
            number
          ) : (
            <Button
              type="link"
              style={{ padding: 0, height: "auto", fontWeight: 400, fontSize: "12px", lineHeight: "12px" }}
              onClick={handleTaskClick}
            >
              {number}
            </Button>
          )}
        </Text>
      </Col>
      <Modal
        title={`Описание задачи ${number}`}
        open={isOpenTaskInfo}
        onOk={handleCloseTaskInfo}
        onCancel={handleCloseTaskInfo}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Закрыть"
      >
        <Text style={{ fontSize: "14px", lineHeight: "15px" }}>{requestDescription}</Text>
      </Modal>
    </Row>
  );
}

export { Number };
