import { ReactElement } from "react";
import { Tag } from "antd";

import { ITask } from "core/app/api/interfaces";

interface FieldProps {
  task: ITask;
}

export function MountedOrStationary({ task }: FieldProps): ReactElement {
  if (task.is_mounted) {
    return <Tag color="cyan">НАВЕСНОЕ</Tag>;
  }
  return <Tag color="geekblue">СТАЦИОНАРНОЕ</Tag>;
}
