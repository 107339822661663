import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  label?: string;
}

function Location({ task, label = "Местоположение (участок)" }: FieldProps): ReactElement {
  return <Field label={label} value={task.location?.name} />;
}

export { Location };
