import { Form, Space, Switch } from "antd";
import { NamePath } from "antd/es/form/interface";
import { ReactElement } from "react";

import { Text } from "core/lib";

type TVandalActionsSwitchProps = {
  name?: NamePath;
  isDisabled?: boolean;
};

export function VandalActionsSwitch({ name, isDisabled }: TVandalActionsSwitchProps): ReactElement {
  return (
    <Space size={8} align="baseline">
      <Form.Item name={name || "is_vandalism_detected"} initialValue={false}>
        <Switch disabled={isDisabled} />
      </Form.Item>
      <Text boldness="semibold" transform="uppercase" size="label2">
        Вандальные действия
      </Text>
    </Space>
  );
}
