import { useCallback } from "react";
import { Row, Col, Button, Form, Spin, Input, InputNumber, Checkbox, message } from "antd";

import { useOpinTaskDraftId } from "core/shared/task/useOpinTaskDraftId";
import { useOpinTaskDraft } from "core/shared/task/useOpinTaskDraft";
import { Text } from "core/lib";
import { StateNamesEnum } from "core/app/api/interfaces";
import { useStateByName } from "core/shared/useTaskState";
import { useUpdateOpinTaskMutation } from "core/app/api/OPIN";

import { TaskUrgencySelector } from "../TaskUrgencySelector";
import { Number } from "../TaskModals/shared/Fields";

import { CreateOpinMedia } from "./Media";
import { MountedFields } from "./MountedFields";
import { StationaryFields } from "./StationaryFields";

export function CreateOpinForm() {
  const [, setOpinTaskDraftId] = useOpinTaskDraftId();
  const { data: opinTaskDraft } = useOpinTaskDraft();
  const [updateRequest, updateResult] = useUpdateOpinTaskMutation();
  const newState = useStateByName(StateNamesEnum.New);
  const [form] = Form.useForm();

  const handleClose = useCallback(() => {
    setOpinTaskDraftId(null);
  }, [setOpinTaskDraftId]);

  const handleFinish = useCallback(
    async (values: any) => {
      const body: Record<string, any> = {
        ...values,
        state_id: newState?.id,
      };

      try {
        await updateRequest({ ...body });
        message.success("Заявка создана успешно.");
        handleClose();
      } catch (e) {
        message.error("При создании заявки произошла ошибка.");
      }
    },
    [handleClose, newState?.id, updateRequest]
  );

  if (!opinTaskDraft) {
    return (
      <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <Form layout="vertical" initialValues={opinTaskDraft} onFinish={handleFinish} form={form}>
      <Form.Item hidden name="id">
        <InputNumber />
      </Form.Item>
      <Form.Item hidden name="location_id">
        <InputNumber />
      </Form.Item>
      <Form.Item hidden name="is_mounted" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Row justify="center" className="mb-2">
        <Col>
          <Text boldness="black" size="h4" className="uppercase">
            Создание поручения опин
          </Text>
        </Col>
      </Row>
      <Row className="mt-1 mb-1">
        <Col span={24}>
          <Number number={opinTaskDraft.number} />
        </Col>
      </Row>
      {opinTaskDraft.is_mounted ? <MountedFields /> : <StationaryFields />}
      <Row className="mt-1 mb-1">
        <Col span={24}>
          <Form.Item label="ПРИОРИТЕТ" name="request_priority">
            <TaskUrgencySelector />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-1 mb-1">
        <Col span={24}>
          <Form.Item
            label="ОПИСАНИЕ"
            name="request_description"
            required
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(new Error("Пожалуйста, введите ОПИСАНИЕ"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-1 mb-1">
        <Col span={24}>
          <CreateOpinMedia />
        </Col>
      </Row>
      <Row gutter={36} className="mt-3">
        <Col span={12}>
          <Button size="large" style={{ width: "100%" }} onClick={handleClose}>
            ОТМЕНИТЬ
          </Button>
        </Col>
        <Col span={12}>
          <Button
            loading={updateResult.isLoading}
            size="large"
            htmlType="submit"
            style={{ width: "100%" }}
            type="primary"
          >
            СОЗДАТЬ ПОРУЧЕНИЕ
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
