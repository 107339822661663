import { Col, Row } from "antd";
import clsx from "clsx";

import { ITask } from "core/app/api/interfaces";
import { EffortTypeEnum } from "core/models/enums";
import {
  AccumulatorElapsedTime,
  EffortField,
  EquipmentType,
  LkuObject,
  RequestCreationDatetime,
} from "core/widgets/TaskModals/shared/Fields";

type LkuGeneralInfoProps = {
  task: ITask;
};

/** Заглавная часть (раздел) в карточке доп. работ ЛКУ. */
export function LkuGeneralInfo({ task }: LkuGeneralInfoProps) {
  return (
    <Row gutter={16} className={clsx("mb-1", "mt-1")}>
      <Col span={12}>
        <LkuObject task={task} />
        <EquipmentType task={task} />
      </Col>
      <Col span={12}>
        <RequestCreationDatetime task={task} />
        <EffortField task={task} effortType={EffortTypeEnum.MAN_HOURS} />
        <AccumulatorElapsedTime task={task} />
      </Col>
    </Row>
  );
}
