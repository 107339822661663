import { Row, Col, Divider, Button, Form, InputNumber, Checkbox } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { isPspaKfTask } from "core/shared/task";
import { useDisabled } from "core/shared/task/useDisabled";
import { useTask } from "core/shared/task/useTask";
import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { ClosingDate } from "core/widgets/TaskModals/shared/ClosingDate";
import { Comment } from "core/widgets/TaskModals/shared/Comment";
import { ElapsedTime } from "core/widgets/TaskModals/shared/ElapsedTime";
import { EmployeesSelect as EmployeesSelectFormControlled } from "core/widgets/TaskModals/shared/EmployeesSelect/FormControlled";
import Media from "core/widgets/TaskModals/shared/Media";
import RepairedUsedMaterialsTextArea from "core/widgets/TaskModals/shared/RepairedUsedMaterialsTextArea";
import { SignatureButtons } from "core/widgets/TaskModals/shared/SignatureButtons";
import StateButtons from "core/widgets/TaskModals/shared/StateButtons/FormControlled";
import TaskTitle from "core/widgets/TaskModals/shared/TaskTitle";
import UsedMaterialsTextArea from "core/widgets/TaskModals/shared/UsedMaterialsTextArea";

import { Body } from "./Body";
import GeneralInfo from "./GeneralInfo";

import "../styles.scss";

/** Карточка технического обслуживания (ТО) для навесного оборудования СГПС. */
export default function SgpsContent(): ReactElement {
  const { data: task } = useTask();
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);
  const isDisabled = useDisabled();
  const [form] = Form.useForm();

  const handleSave = async (values: Partial<ITask>) => {
    updateTaskRequest(values);
  };

  const initialValues = {
    ...task,
    __is_disabled: isDisabled,
  };

  if (task) {
    return (
      <>
        <TaskTitle icon task={task} />
        <>
          <Form form={form} initialValues={initialValues} scrollToFirstError onFinish={handleSave} layout="vertical">
            <Form.Item name="id" label="id" hidden>
              <InputNumber />
            </Form.Item>
            <Form.Item name="__is_disabled" label="__is_disabled" valuePropName="checked" hidden>
              <Checkbox />
            </Form.Item>
            <GeneralInfo task={task} />
            <StateButtons task={task} />
            <Divider />
            <Body />
            <Divider />
            <Row gutter={16}>
              <Col span={13}>
                <EmployeesSelectFormControlled disabled={isDisabled} teamId={task.team_id} />
                <ElapsedTime disabled={isDisabled} />
                <Row>
                  <Col>
                    <ClosingDate value={task.planned_date} />
                  </Col>
                </Row>
              </Col>
              <Col span={11}>
                <Comment disabled={isDisabled} />
                <UsedMaterialsTextArea isSGPS={isPspaKfTask(task)} disabled={isDisabled} />
                {isPspaKfTask(task) && <RepairedUsedMaterialsTextArea disabled={isDisabled} />}
              </Col>
            </Row>
          </Form>
          <Media task={task} />
          <SignatureButtons task={task} />
          <Divider />
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                className="w-100"
                onClick={form.submit}
                loading={updateTaskResult.isLoading}
                disabled={isDisabled}
              >
                Сохранить
              </Button>
            </Col>
          </Row>
        </>
      </>
    );
  }
  return <div />;
}
