import { AppBar as MuiAppBar, Toolbar, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useState, MouseEvent, useCallback } from "react";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Row, Col } from "antd";
import Drawer from "@mui/material/Drawer";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

import { Text } from "core/lib";
import nnLogo from "core/images/nn-logo.svg";
import { AppRoutes } from "core/shared/constants.routes";
import { useAppDispatch } from "core/app/store";
import { logOut } from "core/app/store/token-slice";
import { util } from "core/app/api";
import { usePrintTaskReportModalOpen } from "core/shared/tasks/usePrintTaskReportModalOpen";
import { PrintTaskReportModalStatesEnum } from "core/app/store/temp-slice";
import { useFullScreen } from "core/shared/main/store/useFullScreen";
import { CanIView } from "core/shared/components/CanIView";
import { RoleEnum, rolesWitoutRepresentativesAndCatalogAdmin } from "core/shared/constants";
import { UnitServiceCodesEnum } from "core/shared/unit/types";

import UploadFileWorker from "../BackgroundUpload";

import VersionNumber from "./VersionNumber";
import { UserName } from "./UserName";
import { OfflineSelector } from "./OfflineSelector";
import { TasksButton } from "./TasksButton";

function AppBar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [, setPrintTaskReportModalState] = usePrintTaskReportModalOpen();
  const [isFullScreen] = useFullScreen();

  const handleMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleExitClick = useCallback(() => {
    dispatch(logOut());
    dispatch(util.resetApiState());
  }, [dispatch]);

  const container = window !== undefined ? () => document.body : undefined;

  if (isFullScreen) {
    return <div />;
  }

  return (
    <>
      <MuiAppBar style={{ zIndex: 600, height: "48px" }}>
        <Toolbar style={{ height: "100%", minHeight: 0 }}>
          <Row style={{ width: "100%" }} justify="space-between">
            <Col>
              <Row align="middle" gutter={32}>
                <Col>
                  <Button variant="outlined" color="primary" ghost onClick={handleMenuClick} icon={<MenuOutlined />}>
                    МЕНЮ
                  </Button>
                </Col>
                <Col>
                  <NavLink to={AppRoutes.base}>
                    <img src={nnLogo} alt="logo" style={{ verticalAlign: "middle" }} />
                  </NavLink>
                </Col>
              </Row>
            </Col>
            <Col style={{ alignSelf: "center" }}>
              <OfflineSelector />
            </Col>
            <Col>
              <Row gutter={32} align="middle">
                <Col>
                  <TasksButton />
                </Col>
                <Col>
                  <UserName />
                </Col>
                <Col>
                  <Button onClick={handleExitClick} type="text">
                    ВЫЙТИ
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <UploadFileWorker />
        </Toolbar>
      </MuiAppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{ style: { background: "#e2ebf0", padding: "16px" } }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="text" onClick={handleMenuClose} icon={<CloseOutlined />}>
            Закрыть
          </Button>
        </div>
        <CanIView roles={rolesWitoutRepresentativesAndCatalogAdmin}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Text size="body3" boldness="semibold">
                Сменное задание
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <NavLink to={AppRoutes.teams} onClick={handleMenuClose}>
                <Button type="link" size="large">
                  Формирование бригад
                </Button>
              </NavLink>
              <NavLink to={AppRoutes.tasks} onClick={handleMenuClose}>
                <Button type="link" size="large">
                  Формирование наряд задания
                </Button>
              </NavLink>
              <NavLink to={AppRoutes.tasksForTeams} onClick={handleMenuClose}>
                <Button type="link" size="large">
                  Распределение задач по бригадам
                </Button>
              </NavLink>
              <NavLink to={AppRoutes.taskForShift} onClick={handleMenuClose}>
                <Button type="link" size="large">
                  Задачи на смену
                </Button>
              </NavLink>
            </AccordionDetails>
          </Accordion>
        </CanIView>
        <CanIView withoutRoles={[RoleEnum.CATALOG_ADMIN]}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Text size="body3" boldness="semibold">
                Планирование ТО/Персонала
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <CanIView
                serviceCodes={[UnitServiceCodesEnum.PSPA_ZF]}
                not={[[RoleEnum.CATALOG_ADMIN, UnitServiceCodesEnum.PSPA_ZF]]}
              >
                <NavLink to={AppRoutes.maintenance} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Техническое обслуживание ПСПА
                  </Button>
                </NavLink>
              </CanIView>
              <CanIView serviceCodes={[UnitServiceCodesEnum.SLPI_KF, UnitServiceCodesEnum.SLPI_ZF]}>
                <NavLink to={AppRoutes.slpi} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Техническое обслуживание СЛПИ
                  </Button>
                </NavLink>
              </CanIView>
              <CanIView serviceCodes={[UnitServiceCodesEnum.OOSSA]}>
                <NavLink to={AppRoutes.oossa} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Техническое обслуживание ООССА
                  </Button>
                </NavLink>
              </CanIView>
              <CanIView
                serviceCodes={[UnitServiceCodesEnum.LSO]}
                roles={[RoleEnum.SERVICE_LEAD, RoleEnum.UNIT_LEAD, RoleEnum.TEAM_LEAD]}
              >
                <NavLink to={AppRoutes.pssLso} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Техническое обслуживание ПСС ЛСО
                  </Button>
                </NavLink>
              </CanIView>
              <CanIView
                serviceCodes={[UnitServiceCodesEnum.LKU]}
                roles={[RoleEnum.SERVICE_LEAD, RoleEnum.UNIT_LEAD, RoleEnum.TEAM_LEAD]}
              >
                <NavLink to={AppRoutes.pssLku} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Техническое обслуживание ПСС ЛКУ
                  </Button>
                </NavLink>
              </CanIView>
              <CanIView
                serviceCodes={[UnitServiceCodesEnum.VOLS]}
                roles={[RoleEnum.SERVICE_LEAD, RoleEnum.UNIT_LEAD, RoleEnum.TEAM_LEAD]}
              >
                <NavLink to={AppRoutes.pssVols} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Техническое обслуживание ПСС ВОЛС
                  </Button>
                </NavLink>
              </CanIView>
              <CanIView serviceCodes={[UnitServiceCodesEnum.PSPA_KF]}>
                <NavLink to={AppRoutes.sgps} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Техническое обслуживание СГПС
                  </Button>
                </NavLink>
              </CanIView>
              <CanIView roles={rolesWitoutRepresentativesAndCatalogAdmin}>
                <NavLink to={AppRoutes.employeesSchedule} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    График работы персонала
                  </Button>
                </NavLink>
              </CanIView>
            </AccordionDetails>
          </Accordion>
        </CanIView>
        <CanIView roles={rolesWitoutRepresentativesAndCatalogAdmin}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Text size="body3" boldness="semibold">
                Отчётность
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <CanIView roles={rolesWitoutRepresentativesAndCatalogAdmin}>
                <div>
                  <Button type="link" size="large" onClick={handleMenuClose}>
                    <NavLink to={AppRoutes.base}>Статистика</NavLink>
                  </Button>
                </div>
              </CanIView>
              <CanIView
                serviceCodes={[UnitServiceCodesEnum.PSPA_ZF]}
                roles={[RoleEnum.SERVICE_LEAD, RoleEnum.WORKSHOP_LEAD, RoleEnum.UNIT_LEAD]}
              >
                <div>
                  <Button type="link" size="large" onClick={handleMenuClose}>
                    <NavLink to={AppRoutes.rating}>Рейтинг бригад</NavLink>
                  </Button>
                </div>
              </CanIView>
              <CanIView
                serviceCodes={[UnitServiceCodesEnum.PSPA_ZF]}
                roles={[
                  RoleEnum.SERVICE_LEAD,
                  RoleEnum.RELIABILITY_ENGINEER,
                  RoleEnum.WORKSHOP_LEAD,
                  RoleEnum.UNIT_LEAD,
                  RoleEnum.SHIFT_LEAD,
                ]}
              >
                <Button
                  type="link"
                  size="large"
                  onClick={() => {
                    setPrintTaskReportModalState(PrintTaskReportModalStatesEnum.AnyUnit);
                    handleMenuClose();
                  }}
                >
                  <span style={{ color: "#1890ff" }}>Выгрузка по заявкам для ОПиН</span>
                </Button>
              </CanIView>
              <CanIView
                serviceCodes={[UnitServiceCodesEnum.PSPA_ZF, UnitServiceCodesEnum.PSPA_KF]}
                roles={[
                  RoleEnum.SERVICE_LEAD,
                  RoleEnum.RELIABILITY_ENGINEER,
                  RoleEnum.WORKSHOP_LEAD,
                  RoleEnum.UNIT_LEAD,
                  RoleEnum.SHIFT_LEAD,
                  RoleEnum.TEAM_LEAD,
                ]}
              >
                <NavLink to={AppRoutes.equipments} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Карточка оборудования
                  </Button>
                </NavLink>
              </CanIView>
              <CanIView
                serviceCodes={[UnitServiceCodesEnum.PSPA_ZF]}
                roles={[RoleEnum.SERVICE_LEAD, RoleEnum.WORKSHOP_LEAD, RoleEnum.UNIT_LEAD]}
              >
                <NavLink to={AppRoutes.dailyReport} onClick={handleMenuClose}>
                  <Button type="link" size="large">
                    Отчёт для проведения планерок
                  </Button>
                </NavLink>
              </CanIView>
            </AccordionDetails>
          </Accordion>
        </CanIView>
        <CanIView roles={rolesWitoutRepresentativesAndCatalogAdmin}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Text size="body3" boldness="semibold">
                Справка
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <NavLink to={AppRoutes.help} onClick={handleMenuClose}>
                <Button type="link" size="large" style={{ margin: "8px 0" }}>
                  Видеоинструкции
                </Button>
              </NavLink>
            </AccordionDetails>
          </Accordion>
        </CanIView>
        <CanIView roles={[RoleEnum.CATALOG_ADMIN]}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Text size="body3" boldness="semibold">
                Управление справочниками
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <a href="/catalog-admin/" target="_blank" onClick={handleMenuClose}>
                <Button type="link" size="large" style={{ margin: "8px 0" }}>
                  Справочники
                </Button>
              </a>
            </AccordionDetails>
          </Accordion>
        </CanIView>
        <a href="https://supernika.nornik.ru/" target="_blank" onClick={handleMenuClose} rel="noreferrer">
          <Button type="link" size="large" style={{ margin: "8px 0" }}>
            Суперника
          </Button>
        </a>
        <VersionNumber />
      </Drawer>
    </>
  );
}

export { AppBar };
