import { ReactElement } from "react";
import { Form } from "antd";
import { Dayjs } from "dayjs";

import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";
import DatePicker from "core/widgets/DatePicker";
import { useDate } from "core/shared/main/store/useDate";

import { useRequiredCustomerApproval } from "../../hooks";

function DateSelect(): ReactElement {
  const [date, setDate] = useDate();
  const requiredCustomerApproval = useRequiredCustomerApproval();
  const handleDateChange = (newValue: Dayjs | null): void => {
    if (newValue && newValue.isValid()) {
      setDate(newValue);
    }
  };

  return (
    <Form.Item
      required={!requiredCustomerApproval}
      label="ДАТА"
      colon={false}
      name="plannedDate"
      style={{ marginBottom: 0 }}
    >
      {date && (
        <DatePicker
          allowClear={false}
          style={{ width: "100%" }}
          format={displayDateFormat}
          onChange={handleDateChange}
          size="large"
          value={dayjs(date)}
          disabled={requiredCustomerApproval}
          disabledDate={(currentDate: Dayjs) => {
            if (dayjs().add(-1, "day").diff(currentDate) > 0) {
              return true;
            }
            return false;
          }}
        />
      )}
    </Form.Item>
  );
}

export default DateSelect;
