import React, { useMemo, Key } from "react";
import { Table, Space, Button } from "antd";
import type { TableProps } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import dayjs, { displayDateFormat, displayTimeFormat } from "core/shared/tools/dayjs";
import { mediaCategoryTitles } from "core/shared/task";
import icVideo from "core/images/ic-video.svg";
import icImage from "core/images/ic-image.svg";
import { IAttachment } from "core/app/api/interfaces";
import { useGetTaskAttachmentsQuery } from "core/app/api";
import { useMediaContext } from "core/shared/task/context/mediaContext";
import { useOpinTaskDraft } from "core/shared/task/useOpinTaskDraft";

interface MediaTypeProps {
  contentType: string;
}

function MediaType({ contentType }: MediaTypeProps) {
  if (contentType.includes("image")) {
    return (
      <Space>
        <img style={{ verticalAlign: "middle" }} src={icImage} alt="" /> Фото
      </Space>
    );
  }
  if (contentType.includes("video")) {
    return (
      <Space>
        <img style={{ verticalAlign: "middle" }} src={icVideo} alt="" /> Видео
      </Space>
    );
  }
  return <div />;
}

interface TableFormItemProps {
  value?: Key[];
  onChange?: (attachments: Key[]) => void;
}

export function TableFormItem({ value: _, onChange }: TableFormItemProps) {
  const { data: opinTaskDraft } = useOpinTaskDraft();
  const executionTaskId = opinTaskDraft?.execution_task_id;
  const { data } = useGetTaskAttachmentsQuery(executionTaskId ?? skipToken);
  const context = useMediaContext();
  const { setSelectedMediaId } = context;

  const columns = useMemo(
    () => [
      {
        title: "ТИП МЕДИА",
        render: (record: IAttachment) => (
          <Button
            type="link"
            onClick={() => {
              setSelectedMediaId(record.id);
            }}
          >
            <MediaType contentType={record.content_type} />
          </Button>
        ),
      },
      {
        title: "ДАТА",
        render: (record: IAttachment) => dayjs(record.created_at).format(displayDateFormat),
      },
      {
        title: "ВРЕМЯ",
        render: (record: IAttachment) => dayjs(record.created_at).format(displayTimeFormat),
      },
      {
        title: "СТАТУС",
        render: (record: IAttachment) => mediaCategoryTitles[record.category],
      },
    ],
    [setSelectedMediaId]
  );

  const rowSelection: TableProps<IAttachment>["rowSelection"] = useMemo(
    () => ({
      onChange: (selectedRowKeys: React.Key[], selectedRows: IAttachment[]) => {
        onChange?.(selectedRowKeys);
      },
      getCheckboxProps: (record: IAttachment) => ({
        name: `${record.id}`,
      }),
    }),
    [onChange]
  );
  return <Table size="small" rowKey="id" rowSelection={rowSelection} dataSource={data} columns={columns} />;
}
