import { ReactElement } from "react";
import { Space } from "antd";

import { useUnit } from "core/shared/unit/useUnit";

import { StatisticByTeamPspaTable } from "./ByTeamPspaTable";
import { StatisticByTeamSlpiTable } from "./ByTeamSlpiTable";
import { StatisticByTeamOossaTable } from "./ByTeamOossaTable";
import { StatisticByTeamLsoTable } from "./ByTeamLsoTable";
import { StatisticByTeamLkuTable } from "./ByTeamLkuTable";
import TaskLegend from "./TaskLegend";
import { StatisticByTeamVolsTable } from "./ByTeamVolsTable";

export default function StatisticByTeam(): ReactElement {
  const unit = useUnit();

  return (
    <Space className="w-100" direction="vertical" size={16}>
      <TaskLegend />
      {unit?.isPSPA() && <StatisticByTeamPspaTable />}
      {unit?.isSLPI() && <StatisticByTeamSlpiTable />}
      {unit?.isLSO() && <StatisticByTeamLsoTable />}
      {unit?.isLKU() && <StatisticByTeamLkuTable />}
      {unit?.isVOLS() && <StatisticByTeamVolsTable />}
      {unit?.isOOSSA() && <StatisticByTeamOossaTable />}
    </Space>
  );
}
