import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  label?: string;
}

function InventoryNumber({ task, label }: FieldProps): ReactElement {
  return <Field label={label || "Инвентарный номер"} value={task.system_complex?.inventory_number} />;
}

export { InventoryNumber };
