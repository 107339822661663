import { Col, Row } from "antd";

import {
  AccumulatorElapsedTime,
  Customer,
  CustomerDepartment,
  LsoMaintenanceType,
  EffortField,
  Address,
} from "core/widgets/TaskModals/shared/Fields";
import { EffortTypeEnum } from "core/models/enums";
import { TaskModalEffortBySystemField } from "core/widgets/TaskModals/shared/Fields/EffortBySystem";

import { useLsoMaintenanceContext } from "../../hooks";

export default function LsoTaskModalGeneralInfo() {
  const { task } = useLsoMaintenanceContext();

  if (!task) {
    return null;
  }

  return (
    <Row>
      <Col span={12}>
        <LsoMaintenanceType task={task} />
        <Customer task={task} />
        <CustomerDepartment task={task} label="Подразделение" />
        <Address task={task} />
      </Col>
      <Col span={12}>
        <EffortField task={task} effortType={EffortTypeEnum.MAN_HOURS} />
        <TaskModalEffortBySystemField task={task} />
        <AccumulatorElapsedTime task={task} />
      </Col>
    </Row>
  );
}
