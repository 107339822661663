import { Row, Col } from "antd";

import EquipmentTypeField from "../EquipmentTypeField";

import TransportField from "./TransportField";

export function MountedFields() {
  return (
    <Row className="mt-1 mb-1" gutter={16}>
      <Col span={12}>
        <TransportField />
      </Col>
      <Col span={12}>
        <EquipmentTypeField />
      </Col>
    </Row>
  );
}
