import { Form, InputNumber } from "antd";

export type VolsFootageInputFieldProps = {
  isDisabled?: boolean;
};

function VolsFootageInputField({ isDisabled }: VolsFootageInputFieldProps) {
  return (
    <Form.Item
      rules={[
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error("Необходимо указать фактическое кол-во метров"));
            }
            return Promise.resolve();
          },
        },
      ]}
      required
      name="actual_volume"
      label="ФАКТИЧЕСКОЕ КОЛИЧЕСТВО М."
      className="w-100"
    >
      <InputNumber min={0} disabled={isDisabled} className="w-100" />
    </Form.Item>
  );
}
export default VolsFootageInputField;
